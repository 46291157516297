import { useContext } from "react";
import { EnrollmentContext } from "../../../enrollment";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { errorMsg } from "../errorMsg";
import { Button } from "../../styles/Button.styled";

export const DualResult = ({ statusArr }: {
    statusArr: any
}) => {
    const { isTx } = useContext<any>(EnrollmentContext);

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">We are almost there!</h1>
                {
                    statusArr.map((item: any, idx: number) => {
                        const { commodity, status } = item;
                        return (
                            <div key={idx}>
                                <h3>{commodity}:</h3>
                                <p>{errorMsg[status].paragraph}</p>
                                {
                                    errorMsg[status].paragraph2 &&
                                    <p>{errorMsg[status].paragraph2}</p>
                                }
                            </div>
                        )
                    })
                }
                <div className="mt-4">
                    <Button
                        onClick={() => {
                            document.location.href = isTx ? `tel:8887335557` : `tel:8883556205`
                        }}>
                        Contact Us: { isTx ? '(888) 733-5557' : '(888) 355-6205' }
                    </Button>
                </div>
            </div>
        </EnrollmentResultContent>
    )
}
