import styled from 'styled-components';

export const EnrollmentContainer = styled.div`
    display: flex;
    padding: 24px 0;
    min-height: 500px;

    @media (max-width: 991px) {
        flex-direction: column;
    }
`

export const EnrollmentMain = styled.div`
    flex: 1;
`

export const EnrollmentAside = styled.div`
    width: 360px;
    margin-left: 60px;
    
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 44px;
        margin-top: 44px;
    }
`

interface AsideBoxProps {
    whiteBg?: boolean;
}

export const AsideBox = styled.div<AsideBoxProps>`
    background-color: ${({ whiteBg }) => whiteBg ? '#fff' : '#eff7fa'};
    border-radius: 12px;
    border: ${({ whiteBg }) => whiteBg ? '1px solid #ddd' : '0 none'};
    overflow: hidden;
    padding: 24px 20px;
`

export const AsideBoxHeader = styled.div`
    margin-bottom: 32px;
    
    h5{
        margin: 0;
        font-weight: 700;
        font-size: 17px;
    }
`

export const AsideBoxBody = styled.div`

`

export const EnrollmentStepWrapper = styled.div`
    padding: 20px 0;

    .checkbox-item {
        flex: inherit;
    }
`

export const EnrollmentStepFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-top: 44px;
`

export const EnrollmentRadioGroup = styled.div`
    display: flex;
    margin: 20px -20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

interface EnrollmentRadioBoxProps {
    active?: boolean;
}

export const EnrollmentRadioBox = styled.div<EnrollmentRadioBoxProps>`
    flex: 1;
    border: ${({ active }) => active ? '1px solid rgb(37,63,102)' : '1px solid #ddd'};
    background-color: ${({ active }) => active ? '#F4F8FD' : '#fff'};
    border-radius: 14px;
    padding: 24px 20px;
    cursor: pointer;
    margin: 0 20px;
    position: relative;
    transition: all .3s;
    top: 0;

    :hover {
        border-color: #000000de;
    }

    & > div{
        display: flex;
        align-items: center;
    }

    p{
        margin: 8px 0 0;
        font-size: 14px;
        color: #666;
    }

    @media (max-width: 768px) {
        margin-top: 8px;
        margin-bottom: 8px;
    }
`

export const EnrollmentFormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 16px -20px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 4px;
        margin-bottom: 4px;
    }
`

interface EnrollmentFormColProps {
    full?: boolean;
}

export const EnrollmentFormCol = styled.div<EnrollmentFormColProps>`
    flex: ${({ full }) => full ? '0 0 100%' : '1 0 50%'};
    padding: 0 20px;

    a {
        color: rgb(0, 183, 241);
    }

    .react-datepicker-wrapper input[type="text"] {
        width: 100%;
        height: 38px;
        padding: 0 11px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .dropdown button{
        width: 100%;
        border: 1px solid #ced4da;
        font-size: 1rem;
        text-transform: capitalize;
    }

    .checkbox-item{
        padding-left: 0;
        padding-right: 0;
    }

    .error{
        ${EnrollmentRadioBox} {
            border-color: #f15536;
        }
    }

    @media (max-width: 768px) {
        flex: 0 0 100%;
        width: 100%;
    }
`

export const EnrollmentAddressWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    background-color: #fafafa;
    padding: 24px 32px;
    border-radius: 12px;
    border: 1px solid #ddd;

    b {
        font-weight: 500;
        margin-right: 6px;
    }

    p{
        margin: 8px 0;
    }

    a{
        display: inline-block;
        text-decoration: none;
        margin-left: 8px;
    }
`

export const CreditCheckSection = styled.div`
    background-color: #fafafa;
    padding: 24px 32px;
    border-radius: 12px;
    border: 1px solid #ddd;
    margin: 24px 0;

    h5 {
        margin-bottom: 24px;
    }

    p {
        color: #666;
    }

    p b {
        font-weight: 500;
    }

    ${EnrollmentRadioBox} {
        flex: auto;
        border: 0 none;
        padding: 0;
        background-color: transparent;
    }
`

export const ReviewWrapper = styled.div`
    h5 {
        margin: 20px 0 -8px;
        padding: 0 20px;
    }
`

export const ReviewContent = styled.div`
    
`

export const ReviewBlock = styled.div`
    padding: 8px 20px;

    h6 {
        margin-bottom: 16px;
        padding-bottom: 8px;
        font-size: 17px;
        border-bottom: 1px solid #eee;
    }
`

export const ReviewRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
`

export const ReviewDisclaimer = styled.div`
    margin-top: 32px;
    font-size: 14px;
    color: #666;
    font-weight: 300;
    padding: 0 20px;
`

interface PlanSummaryRowProps {
    narrow?: boolean;
}

export const PlanSummaryRow = styled.div<PlanSummaryRowProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ narrow }) => { return narrow ? '4px 0' : '12px 0' }};
    font-size: 14px;
    line-height: 1.2;

    label {
        font-weight: 500;
    }

    span {
        font-size: 15px;
    }

    b {
        font-size: 20px;
        font-weight: 600;
    }
`

export const InputWithMask = styled.div`
    position: relative;

    .mask-input {
        position: absolute;
        bottom: 0;
        left: 0;
    }
`

interface InputMaskToggleProps {
    active: boolean;
}

export const InputMaskToggle = styled.button<InputMaskToggleProps>`
    display: inline-block;
    width: 24px;
    height: 24px;
    mask: ${({ active }) => active ? 'url("/images/EyeOff.svg") 0 0 no-repeat' : 'url("/images/Eye.svg") 0 0 no-repeat'};
    mask-size: cover;
    background-color: #FFAF19;
    border: 0 none;
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 2;
    padding: 0;
`

export const ErrorModalBody = styled.div`
    text-align: center;
    padding: 44px 0;

    h2 {
        font-size: 26px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        margin-bottom: 44px;
        
        a {
            color: ${({ theme }) => theme.colors.secondary};
            text-decoration: none;
            font-weight: 600;
        }
    }
`

export const ResultPage = styled.div`
    max-width: 800px;
    margin: 100px auto 50px;
    color: #253f66;
    
    p {
        font-size: 18px;
        opacity: .8;

        span {
            opacity: .6;
            font-size: 14px;
        }
    }
`

export const InputZipcode = styled.div`
    display: inline-flex;
    column-gap: 20px;
    position: relative;

    input {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 15px 16px;
        position: relative;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 14px;
        font-size: 16px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    input:hover {
        border-color: #000000de;
    }

    button {
        font-size: 14px;
        padding: 17px 12px !important;
    }

    button:hover {
        color: #000;
    }

    .spinner-border {
        position: absolute;
        top: 18px;
        right: 18px;
    }

    @media (max-width: 1170px) {
        flex-direction: column;
    }
`

export const AddressToggleRow = styled.div`
    color: #666;
    border-radius: 12px;
    padding: 20px 8px;
    margin-top: 24px;
    font-size: 18px;

    span {
        text-decoration: underline;
        cursor: pointer;
    }
`

export const CreateAccountSection = styled.div`
    background-color: #fafafa;
    padding: 24px 32px;
    border-radius: 12px;
    border: 1px solid #ddd;
    margin: -12px 0 0 30px;

    h5 {
        margin-bottom: 24px;
    }

    p {
        color: #666;
    }

    p b {
        font-weight: 500;
    }

    ${EnrollmentRadioBox} {
        flex: auto;
        border: 0 none;
        padding: 0;
        background-color: transparent;
    }
`

export const ReadOnlyInput = styled.div`
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #666;
    background-color: #fafafa;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 14px;
`

export const EnrollmentResultContent = styled.div`
    p {
        font-size: 18px;
    }

    a, a:hover {
        color: rgb(37,63,102);
        text-decoration: underline;
    }
`

export const CheckboxDesc = styled.p`
    margin-top: -8px;
    font-size: 15px;
    color: #253f66;
    opacity: .8;
    margin-left: 26px;
    line-height: 1.1;
`

export const PlanSummaryItem = styled.div`
    margin-bottom: 24px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    &:last-child {
        border-bottom: 0 none;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
`