import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { PlanProps } from "../types";
import { LoadingRow } from "./loading-row";
import { PlanDesc } from "./PlanDesc";
import { Button } from "./styles/Button.styled";
import { PlanSummaryRow } from "./styles/Enrollment.styled";
import { StyledPlanRow, StyledPlanList, PlanUtilityRow, PlanDetailBlock } from "./styles/Plan.styled";
import { getCommodityIconSrc } from "../common";
import { ArrowRightOutlined,FireOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
// import ElectricityIcon from '../../public/images/electricityIcon.svg';


export const UtilityModal = ({
    show, handleClose, data, selectProduct, isTx, utilityList, utility, handleChangeUtility, loading, selectedPlans, handleConfirm, isDefaultGas, setMultiUtility,setOptionSelected, disableContinueButton
}: {
    show: boolean;
    handleClose: Function;
    data: any;
    selectProduct: Function;
    isTx: boolean;
    utilityList: any;
    utility: any;
    handleChangeUtility: Function;
    loading: boolean;
    selectedPlans: any;
    handleConfirm: Function;
    isDefaultGas: boolean;
    setMultiUtility:Function;
    setOptionSelected: Function;
    disableContinueButton: boolean;
}) => {
    const { t } = useTranslation('enrollment');
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [utilityFinal,setUtilityFinal] = useState<any>({});
    const [utilityName,setUtilityName] = useState("")

    
    useEffect(()=>{
        console.log('Data in selectedPlans ==>', utility);
        const arr = {...utilityList}; 
        const keys = Object.keys(arr);
        if (keys.length > 1) {
            // Create the "both" key with the combined data
            // arr.Both = keys.reduce((combinedData, key) => {
            //     return combinedData.concat(arr[key]);
            // }, []);
            arr.Both = {...utilityList};
        }
        setUtilityFinal(arr);
    },[utilityList])

    const sortByName = (a:any, b:any) => {
        if (a.plan_name === "Breathe Easy 24" && b.plan_name !== "Breathe Easy 24") {
            return -1; 
        } else if (a.plan_name !== "Breathe Easy 24" && b.plan_name === "Breathe Easy 24") {
            return 1; 
        } else {
            return 0;
        }
    };

    
    const utilityNameWrapper = (utility_name: string) => {
        let targetUtilities = ["NStar - BECO", "NStar - CAMB", "NStar - COM", "WMECO"];
    
        const cleanedUtilityName = utility_name?.replace(/\s/g, '');
        const cleanedTargetUtilities = targetUtilities.map(utility => utility?.replace(/\s/g, '').toLowerCase());
        const index = cleanedTargetUtilities.indexOf(cleanedUtilityName.toLowerCase());
        
        if (index !== -1) {
            return `Eversource (${targetUtilities[index]?.replace("NStar", "")?.replace("-", " ").trim()})`;
        } else {
            return utility_name;
        }
    }

    const planBlock = () => {
        if (!utilityList || !utility) return null;
        const hasElectricPlans = data && !!data['Electric'];

        const handleMouseEnter = (index:any) => {
            setHoveredIndex(index);
        };
        
        const handleMouseLeave = () => {
            setHoveredIndex(null);
        };
        
        
        return (
            <div >
            {/* className={`${Object.keys(utilityFinal).length <= 1 ? 'hide-tabs' : ''}`}> */}
                <Tabs
                    variant="pills"
                    // defaultActiveKey={(isDefaultGas || !hasElectricPlans) ? Object.keys(utilityFinal)[1] : Object.keys(utilityFinal)[0]}
                    // id="plan-modal-commodity-tab"
                    defaultActiveKey={Object.keys(utilityFinal).includes("Both")?"Both":Object.keys(utilityFinal)[0]}
                >
                    {
                        Object.keys(utilityFinal).map((key, idx) => {
                            const plans = data ? (data[key] ? [...data[key]].sort(sortByName) : data[key]) || [] : [];
                            const findUtility =key !== "Both" && utilityFinal[key]?.find((item: any) => (item.utility_code == utility[key] || item.id_utility == utility[key]) );
                            const currentUtilityName = findUtility?.name;
                            const currentUtilityId = findUtility?.id_utility;
                            const findPlan = selectedPlans.filter((plan: any) => plan.commodity_name === key);
                            return (
                                <Tab eventKey={key}  key={idx} title={(() => {
                                    return <span onClick={()=>{
                                            setOptionSelected(key)
                                    }} style={{color:"#979797"}}>
                                            <span style={{display:"flex", alignItems:"center"}}>
                                                {t(key)}
                                                {key == "Electric" && <img style={{paddingLeft:"4px"}} src='/images/electricityIcon.svg' />}
                                                {key == "Gas" && <FireOutlined style={{color:"#274067",fontSize:"20px"}} rev={undefined} />}
                                            </span>
                                            { findPlan.length > 0 && <i>{findPlan.length}</i> }
                                        </span>
                                })()}>
                                    <PlanDetailBlock>
                                        {
                                         key !== "Both" &&  
                                            <>
                                                {
                                                    utilityFinal[key].length === 1 &&
                                                    // <PlanUtilityRow><b>{t('Service Area')}:</b>{currentUtilityName}</PlanUtilityRow>
                                                    <div key={idx} style={{padding:"12px 20px", margin:"16px 0px",borderRadius:"8px",backgroundColor:hoveredIndex === idx ?"#EFF7FA": "#F4F4F7", 
                                                    display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center"}}
                                                    >
                                                        <span style={{display:"flex",alignItems:"center"}}>
                                                            <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/checkedRadio.png' />
                                                            <span style={{fontSize:"20px",color:"#274067"}}>{utilityNameWrapper(currentUtilityName)}</span>
                                                        </span>
                                                        <img width="37px" height="15px" src='/images/arrowRight.png' />
                                                    </div>

                                                }
                                                {
                                                    utilityFinal[key].length > 1 &&
                                                      utilityFinal[key].map((val: any, idx: number) => (
                                                        <div key={idx} style={{padding:"12px 20px", margin:"16px 0px",borderRadius:"8px",backgroundColor:hoveredIndex === idx ?"#EFF7FA": "#F4F4F7", 
                                                            display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center"}}
                                                            onClick={() => {
                                                                if(disableContinueButton) return
                                                                handleChangeUtility(key, val.utility_code);
                                                            }}
                                                            onMouseEnter={() => handleMouseEnter(idx)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <span style={{display:"flex",alignItems:"center"}}>
                                                                {val.id_utility === currentUtilityId? <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/checkedRadio.png' />: <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/uncheckedRadio.png'/>}
                                                                <span style={{fontSize:"20px",color:"#274067"}}>{utilityNameWrapper(val?.name)}</span>
                                                            </span>
                                                            <img width="37px" height="15px" src='/images/arrowRight.png' />
                                                        </div>
                                                      ))
                                                }
                                            </>
                                        }
                                        {
                                            key === "Both" && 
                                            Object.keys(utilityFinal[key])?.map?.((utilityBoth)=>{
                                                const findUtilityBoth = utilityFinal[key][`${utilityBoth}`]?.find((item: any) => (item.utility_code == utility[utilityBoth] || item.id_utility == utility[utilityBoth]) );
                                                const currentUtilityNameBoth = findUtilityBoth?.name;
                                                const currentUtilityId = findUtilityBoth?.id_utility;
                                                return<div>
                                                    <div style={{display:"flex",alignItems:"center",background: "#F4F4F7",justifyContent:"space-between",padding:" 8px 20px"}}>
                                                        <span style={{display:"flex",alignItems:"center"}}><span style={{fontSize:"24px",color:"#274067",fontWeight:"500",marginRight:"4px"}}>{utilityBoth}</span>
                                                        {utilityBoth == "Electric" && <img style={{paddingLeft:"4px"}} src='/images/electricityIcon.svg' />}
                                                        {utilityBoth == "Gas" && <FireOutlined rev={undefined} />}</span>
                                                        <img width="37px" height="15px" src='/images/arrowRight.png' />

                                                    </div> 
                                                        <div style={{background: "#F4F4F7"}}><div style={{background:"#C5C5C5",height:"2px",margin:"0px 20px 0px 20px"}}></div></div>

                                                   { utilityFinal[key][`${utilityBoth}`].length === 1 &&
                                                    // <PlanUtilityRow><b>{t('Service Area')}:</b>{currentUtilityName}</PlanUtilityRow>
                                                    <div key={idx} style={{padding:"8px 20px", margin:"0px",borderRadius:"0px",backgroundColor:hoveredIndex === idx ?"#EFF7FA": "#F4F4F7", 
                                                        display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center"}}
                                                    >
                                                        <span style={{display:"flex",alignItems:"center"}}>
                                                            <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/checkedRadio.png' />
                                                            <span style={{fontSize:"20px",color:"#274067"}}>{utilityNameWrapper(currentUtilityNameBoth)}</span>
                                                        </span>
                                                    </div>
                                                    }
                                                    {
                                                    utilityFinal[key][`${utilityBoth}`].length > 1 &&
                                                      utilityFinal[key][`${utilityBoth}`].map((val: any, idx: number) => {
                                                        return(
                                                        <div key={idx} style={{padding:"8px 20px", margin:"0px",borderRadius:"0px",backgroundColor:hoveredIndex === idx ?"#EFF7FA": "#F4F4F7", 
                                                            display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center"}}
                                                            onClick={() => {
                                                                if(disableContinueButton) return
                                                                handleChangeUtility(utilityBoth, val.utility_code);
                                                            }}
                                                            onMouseEnter={() => handleMouseEnter(idx)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <span style={{display:"flex",alignItems:"center"}}>
                                                                {val.id_utility === currentUtilityId? <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/checkedRadio.png' />: <img style={{marginRight:"11px"}} width="12px" height="12px" src='/images/uncheckedRadio.png'/>}
                                                                <span style={{fontSize:"20px",color:"#274067"}}>{utilityNameWrapper(val?.name)}</span>
                                                            </span>
                                                           
                                                        </div>
                                                      )})
                                                    }
                                                    <br/>
                                            </div>
                                            })
                                        }
                                    </PlanDetailBlock>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
                <style>
                    {`
                    .nav-pills .nav-item {
                        background: #F4F4F7;
                        border: 0px;
                        font-size: 24px;
                        font-weight: 500;
                        width: auto;
                        border-radius: 0;
                        margin: 5px;
                    }
                    .nav-pills .nav-item.active {
                        color: #fff;
                        font-weight: 500;
                        border-radius: 9px;
                        background: #FFF;
                        width: ${Object.keys(utilityFinal).length > 1 ? "auto" : "100%"};
                        display: flex;
                        justify-content: center;                        
                        margin: 5px;
                        border: 2px solid #00B7F1;
                        box-shadow: 6px -6px 25px 0px rgba(159, 197, 255, 0.25), -6px 6px 25px 0px rgba(159, 197, 255, 0.25);
                    }
                    .nav-pills, .nav-pills:hover {
                        display: flex;
                        justify-content: ${Object.keys(utilityFinal).length > 1 ? "space-between" : "center"};
                        margin: 0;
                        background: #F4F4F7;
                        border-radius: 9px;
                    }
                    @media (max-width: 768px) {
                        .nav-pills .nav-item {
                           padding: 4px 8px;
                        }
                    }
                    `}
                </style>
            </div>
        )
    }

    useEffect(()=>{
        const arr = {...utilityList}; 

        const keys = Object.keys(arr);
        if (keys.length > 1) {
            // Create the "both" key with the combined data
            // arr.Both = keys.reduce((combinedData, key) => {
            //     return combinedData.concat(arr[key]);
            // }, []);
            arr.Both = {...utilityList};
        }
        setUtilityFinal(arr);
    },[utilityList])

    return (
        <Modal id="utility-modal"  show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize:"24px"}}>{t("Select your utility")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { planBlock() }
            </Modal.Body>
            {
                // (!isTx && (utilityList && Object.keys(utilityList).length > 1)) &&
                <Modal.Footer>
                    <Button onClick={() => disableContinueButton ? null : handleConfirm()} className="position-relative" style={{backgroundColor: disableContinueButton ? "grey" : ""}}>
                        {t('Continue')}
                        {disableContinueButton && <span style={{marginLeft: "10px"}}><Loader /></span>}
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    )
}
