import { StyledFormError } from '../styles/FormError.styled';

interface FormErrorProps {
    message?: string;
}

export const FormError: React.FC<FormErrorProps> = ({ message }) => {
    if (!message) return null;
    return (
        <StyledFormError>
            { message }
        </StyledFormError>
    )
}