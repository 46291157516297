import { useEffect, useState } from "react";
import { Button } from '../../styles/Button.styled'
import { checkDebtPayStatus, checkExistingCustomer, getConfigById, sendCustomMagicLink } from "../../../api/api";
import { CircularProgress } from "@mui/material";
import { message } from "antd";
import { getEnrollmentRecord, saveEnrollment } from "../../../api/enrollment";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
    show: boolean;
    enrTokens: any;
    inputValue: any;
    handleSavePre: Function;
    checkedExistingCustomer: boolean;
    setCheckedExistingCustomer: Function;
    setSamePremise: Function;
}

const keyStyle = {color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%',width:"32%"}
const valueStyle = {color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '700', lineHeight:'auto', width:"70%"}
const keyStyle2 = {color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%',width:"40%"}
const valueStyle2 = {color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '700', lineHeight:'auto', width:"60%"}

const ExistingCustomer: React.FC<Props> = ({show, enrTokens, inputValue, handleSavePre, checkedExistingCustomer, setCheckedExistingCustomer,setSamePremise}) =>{
    const [ existingCustomer, setExistingCustomer ] = useState([]);
    const [ priorCustomer, setPriorCustomer ] = useState([]);
    const [ premiseStatus, setPremiseStatus ] = useState<any>({});
    const [ isMobile, setIsMobile ] = useState(false);
    const [ debtDetails, setDebtDetails ] = useState<{debt: number, debt_status: string}>({
        debt: 0,
        debt_status: ""
    });
    const [ userEmail, setUserEmail ] = useState<string>()
    const [ fetchPaymentData, setFetchPaymentData ] = useState<boolean>(false)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ magicLinkSentDebtLoading, setMagicLinkSentDebtLoading] = useState(false)
    const [ currentCustData, setCurrentCustData ] = useState<any>({})
    const [ checkedOnce, setCheckedOnce ] = useState<boolean>(false)
    const [ SHOW_LINK_FOR_DEBT_PAY, SET_SHOW_LINK_FOR_DEBT_PAY ] = useState<boolean>(false)
    const [ debtType, setDebtType] = useState("")
    const [displayContinue,setDisplayContinue] = useState(true)
    const { t, i18n } = useTranslation('enrollment');


    useEffect(()=>{
        (async()=>{
           await fetchConfigData();
        })()
    },[])

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchConfigData = async() =>{
        let res = await getConfigById("SHOW_LINK_FOR_DEBT_PAY");
        const flag = Boolean(Number(res?.config_details?.ConfigData))
        SET_SHOW_LINK_FOR_DEBT_PAY(flag)
    }

    const fetchExistingCustomerData = async() =>{
        setLoading(true)
        fetchConfigData()

        let res = await handleSavePre();
        
        if(res?.length > 0){
            try{
                if(show){
                    setExistingCustomer([]);
                    setPriorCustomer([]);
                    setPremiseStatus({});
                    let res = await checkExistingCustomer(enrTokens[0]?.enrollment_id, enrTokens[0]?.sign);
                    if(res?.status === 200){
                        if(res?.response?.existing_customer?.length > 0){
                            setExistingCustomer(res?.response?.existing_customer)
                            console.log('Data in res?.response?.existing_customer ==>', res?.response?.existing_customer)
                            res?.response?.existing_customer?.map((x:any)=>{
                                if(x.customer_category.includes("same premise")){
                                    setSamePremise(true)
                                }
                            })
                        }
                        if(res?.response?.prior_customer?.length > 0){
                            setPriorCustomer(res?.response?.prior_customer)
                        }
                        if(res?.response?.premise_status){
                            setPremiseStatus(res?.response?.premise_status)
                        }
                        let amt = 0;
                        res?.response?.prior_customer?.map((item:any)=>{
                            if(Number(item?.AR_AMT)!=0){
                                amt = amt+ Number(item?.AR_AMT);
                                setDebtType("AR")
                            }else if(Number(item?.WRITE_OFF_AR_AMT)!=0){
                                amt = amt+ Number(item?.WRITE_OFF_AR_AMT);
                                setDebtType("Writeoff")
                            }
                        })
                        setDebtDetails({
                            debt: amt,
                            debt_status: amt>0?"Unpaid" :"Not Applicable"
                        })
                        if(SHOW_LINK_FOR_DEBT_PAY){
                            setCheckedExistingCustomer(amt>0 ? false : true)
                            setDisplayContinue(amt>0 ? false : true)
                        }else if(amt>0 && !SHOW_LINK_FOR_DEBT_PAY){
                            setCheckedExistingCustomer(false)
                            setDisplayContinue(true)
                        }                        
                        else{
                            setCheckedExistingCustomer(true)
                            setDisplayContinue(false)
                        }
                        setCheckedOnce(true)
                    }

                    let resAddr = await getEnrollmentRecord(enrTokens[0]?.enrollment_id, enrTokens[0]?.sign)
                    let customerData = resAddr?.response;
                    setCurrentCustData(customerData);

                }
            }catch(err){
                setCheckedExistingCustomer(false)
            }
        }
        setLoading(false)
    }

    const sendMagicLink = async() =>{
        if(!userEmail) return;
        try{
            setMagicLinkSentDebtLoading(true)
            let res = await sendCustomMagicLink(enrTokens[0]?.enrollment_id, enrTokens[0]?.sign, userEmail,i18n.language === 'es' ? 'spanish' : 'english', "debtpayment", "debtPay")
            if(JSON.parse(res.response)?.sendResult === "SENT"){
                message.success({
                    content: 'Magic Link Mail sent successfully for Debt Payment',
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
            }
            else{
                message.error({
                    content: JSON.parse(res.response)?.message,
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
            }
            setMagicLinkSentDebtLoading(false)
        }catch(err){
            setMagicLinkSentDebtLoading(false)
            message.error({
                content: 'Failed to send Mail for Debt Payment',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
        }
    }

    const fetchPaymentStatus = async() =>{
        // console.log("API Needed");
        setFetchPaymentData(true)
        try{
            let res = await checkDebtPayStatus(enrTokens[0]?.enrollment_id, enrTokens[0]?.sign);
            if(res?.response?.confirmation_number?.length > 0){
                setDebtDetails({
                    ...debtDetails,
                    debt_status: "Paid"    
                })
                setCheckedExistingCustomer(true)
            }
        }catch(err){

        }
        setFetchPaymentData(false)
    }

    if (!show) return null
    return (
        <>
        {
            checkedOnce ?
            <>
                <Button type="button" onClick={() => fetchExistingCustomerData()}>
                    {t('Re-Check Existing Customer')} &nbsp;&nbsp;&nbsp;&nbsp;
                    {loading && (
                        <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            className="ms-2"
                        />
                    )}
                </Button>
                <div className='container mt-5 p-4 flex' style={{borderRadius: '11px', background: '#EFF7FA', width: '100%', height:  'auto'}}>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='me-2' style={{color: '#FF843F', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>Warning: </div>
                        <div className='me-3' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{existingCustomer.length > 0 && priorCustomer.length > 0? `Existing and Prior Customer`: (existingCustomer.length > 0 ? `Existing Customer`: (priorCustomer.length > 0 ? `Prior Customer` : `None`))} </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='d-flex align-items-center mb-2'>
                                <div style={{color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'154.182%'}}>{currentCustData?.service_address} {currentCustData?.service_city} {currentCustData?.service_county} {currentCustData?.service_state}</div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Premise Status:</div>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{premiseStatus?.premise_active === "Yes" ?  "Active" : "Not Active"}</div>
                            </div>
                            <div className='d-flex align-items-center mb-4'>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Bad Debt:</div>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{debtDetails?.debt > 0 ? `$${debtDetails?.debt} (${debtType})` : `None${debtDetails?.debt<0?(" (Credit Amount: $"+ Math.abs(Number(debtDetails?.debt))+")"):''}`}</div>
                                {debtDetails?.debt > 0 && (fetchPaymentData ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <img src="/images/refreshIcon.svg" height="24px" width="24px" style={{cursor: 'pointer', marginLeft: "10px"}} onClick={()=>fetchPaymentStatus()}/>)}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-3' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>ESIID: </div>
                                <div style={{color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:isMobile?'normal':'154.182%'}}>{currentCustData?.ldc_account}</div>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Customer Status:</div>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{existingCustomer.length > 0 && priorCustomer.length > 0? `Existing and Prior Customer`: (existingCustomer.length > 0 ? `Existing Customer`: (priorCustomer.length > 0 ? `Prior Customer` : `None`))} </div>
                            </div>
                            <div className='d-flex align-items-center mb-4'>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Status:</div>
                                <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{debtDetails?.debt_status}</div>
                                {debtDetails?.debt_status != "Not Applicable" && (fetchPaymentData ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <img src="/images/refreshIcon.svg" height="24px" width="24px" style={{cursor: 'pointer', marginLeft: "10px"}} onClick={()=>fetchPaymentStatus()}/>)}
                            </div>
                        </div>
                    </div>

                    {/* Existing Customer */}
                    {existingCustomer.length > 0 && <div><b>Existing Customer</b></div>}
                    { existingCustomer.length > 0 &&
                        existingCustomer.map((data: any, index: number)=>{
                            return <div style={{width:"100%"}}>
                                <div style={{display:'flex',marginTop:"10px"}}>
                                    <div style={{width:"50%"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>First Name:</div>
                                            <div style={valueStyle}> {data.customer_firstname || "-"}</div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>Service Address:</div>
                                            <div style={valueStyle}> {data.SERVICE_ADDRESS || "-"}</div>
                                        </div>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>Last Name:</div>
                                            <div style={valueStyle}> {data.customer_lastname || "-"}</div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>LDC Number:</div>
                                            <div style={valueStyle}> {data.ldc_number || "-"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{display:"flex"}}>
                                        <div style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%',width:"16%"}}>Customer Category:</div>
                                        <div style={valueStyle}> {data.customer_category || "-"}</div>
                                    </div>
                                </div>
                                {(priorCustomer.length > 0 || premiseStatus?.premise_active?.toLowerCase() === "yes") && <div style={{borderTop: "1px solid #457180", margin: "10px 0px"}} />}
                            </div>
                        })
                    }

                    {/* Prior Customer */}
                    {priorCustomer.length > 0 && <div style={{marginTop: "20px"}}><b>Prior Customer</b></div>}
                    { priorCustomer.length > 0 &&
                        priorCustomer.map((data: any, index: number)=>{
                            return <div style={{width:"100%"}}>
                                <div style={{display:'flex',marginTop:"10px"}}>
                                    <div style={{width:"50%"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle2}>First Name:</div>
                                            <div style={valueStyle2}> {data.customer_firstname || "-"}</div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle2}>Service Address:</div>
                                            <div style={valueStyle2}> {data.SERVICE_ADDRESS || "-"}</div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle2}>Last Contract End Date:</div>
                                            <div style={valueStyle2}> {data?.CONFIRMED_DROP_DATE || "-"}</div>
                                        </div>
                                    </div>
                                    <div style={{width:"50%"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>Last Name:</div>
                                            <div style={valueStyle}> {data.customer_lastname || "-"}</div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={keyStyle}>LDC Number:</div>
                                            <div style={valueStyle}> {data.ldc_number || "-"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{display:"flex"}}>
                                        <div style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%',width:"20%"}}>Customer Category:</div>
                                        <div style={valueStyle}> {data.customer_category || "-"}</div>
                                    </div>
                                </div>
                                {(premiseStatus?.premise_active?.toLowerCase() === "yes") && <div style={{borderTop: "1px solid #457180", margin: "10px 0px"}} />}
                            </div>
                        })
                    }
                    
                    {/* Premise Status */}
                    {(premiseStatus?.premise_active?.toLowerCase() === "yes") && <div><b>Premise Status</b></div>}
                    {(premiseStatus?.premise_active?.toLowerCase() === "yes") &&
                        <div style={{width:"100%"}}>
                            <div style={{display:'flex',marginTop:"10px"}}>
                                <div style={{width:"50%"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={keyStyle}>First Name:</div>
                                        <div style={valueStyle}> {premiseStatus?.customer_firstname || "-"}</div>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={keyStyle}>Customer Status:</div>
                                        <div style={valueStyle}> {premiseStatus?.customer_status || "-"}</div>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={keyStyle}>Commodity Name:</div>
                                        <div style={valueStyle}> {premiseStatus?.account_package_description || "-"}</div>
                                    </div>
                                </div>
                                <div style={{width:"50%"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={keyStyle}>Last Name:</div>
                                        <div style={valueStyle}> {premiseStatus?.customer_lastname || "-"}</div>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={keyStyle}>Premise Active:</div>
                                        <div style={valueStyle}> {premiseStatus?.premise_active || "-"}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{display:"flex"}}>
                                    <div style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%',width:"16%"}}>Customer Category:</div>
                                    <div style={valueStyle}> {premiseStatus?.customer_category || "-"}</div>
                                </div>
                            </div>
                        </div>
                    }
                    

                    {(debtDetails.debt > 0 && SHOW_LINK_FOR_DEBT_PAY) && <div>
                        <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%', marginTop: '20px'}}>Send Magic Link for Payment:</div>
                        <div className='mt-2 d-flex'>
                                <input className='me-4' type='text' style={{borderRadius: '10px', border: '1px solid #CED4DA', width: '557px', height:'38px'}} placeholder={`enter email address`} onChange={(e)=>setUserEmail(e.target.value)}/>
                                {magicLinkSentDebtLoading ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <a type='submit' onClick={()=>sendMagicLink()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                        <circle cx="16.5" cy="16.5" r="16.5" fill="#617692"/>
                                        <path d="M7.47839 16.571C6.81763 16.9176 6.84504 17.4253 7.53778 17.6995L9.59032 18.5116C10.2836 18.7868 11.3327 18.6366 11.9204 18.178L20.8336 11.1489C21.4195 10.6881 21.4823 10.756 20.9735 11.3008L13.9273 18.8428C13.4167 19.3859 13.5675 20.053 14.2631 20.3219L14.5035 20.4156C15.1991 20.6846 16.3327 21.1363 17.0238 21.4173L19.3002 22.3442C19.9918 22.6252 20.7308 22.294 20.9272 21.5744L24.3967 8.8217C24.5925 8.10212 24.2122 7.79658 23.5514 8.14267L7.47839 16.571Z" fill="white"/>
                                        <path d="M13.1111 24.9198C13.0705 25.0415 14.5148 22.8285 14.5148 22.8285C14.9232 22.2048 14.6913 21.4607 14.0014 21.1786L12.4263 20.5338C11.7364 20.2517 11.4058 20.5829 11.6913 21.2722C11.6913 21.2722 13.1528 24.7948 13.1111 24.9198Z" fill="white"/>
                                    </svg>
                                </a>}
                        </div>
                    </div>}
                </div>
                {!SHOW_LINK_FOR_DEBT_PAY &&
                    <div style={{display:displayContinue?"flex":"none",justifyContent:"right",marginTop:"20px"}}>
                        <Button type="button" onClick={() => {setCheckedExistingCustomer(true); setDisplayContinue(false)}}>
                            Continue
                        </Button>
                    </div>
                }
            </>:
            <Button type="button" onClick={() => fetchExistingCustomerData()}>
                {t('Check Existing Customer')} &nbsp;&nbsp;&nbsp;&nbsp;
                {loading && (
                    <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        className="ms-2"
                    />
                )}
            </Button>
        }
        </>
      )
}

export default ExistingCustomer;