import React from 'react';
import { useState } from 'react';
import { Button, Modal, Box, List, ListItem, TextField, AppBar, Toolbar, Typography, IconButton, Accordion, AccordionDetails, AccordionSummary, Paper, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HelpIcon from '@mui/icons-material/Help';
import ChatIcon from '@mui/icons-material/Chat';
import { Help, Notes, Script } from './tabIcons';
import AvatarRenderer from '../AvatarRenderer';

const tabColors = {
    dark: "#102547",
    light: "#27B6EA"
}

const simpleChat = (data) => {
    return(
        <p style={{color:'#253F66', fontWeight:'bold', textAlign: "left"}}><div dangerouslySetInnerHTML={{ __html: data }} /></p>
    )
}

const qnaChat = (data) => {
    return(
        <div style={{padding:'2px'}}>
            <Accordion style={{marginBottom:'10px', border:'1px solid #B4D6FF'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography style={{color:'#253F66', fontWeight:'bold', fontSize:'small', textAlign: "left"}}><div dangerouslySetInnerHTML={{ __html: data?.res }}/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography style={{color:'#253F66', fontSize:'small', textAlign: "left"}}>
                    <div dangerouslySetInnerHTML={{ __html: data?.ans }}/>
                </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const jsonParser = (data) => {
    try{
        let parsedData = JSON.parse(data);
        return parsedData;
    }catch(err){
        return [
            {
                isQna: false,
                content: "No script available"
            }
          ]
    }
}

const ChatSection = (data) => {
    return (
        <div>
            <h2 style={{color:'#253F66', margin:'auto', textAlign: "left", marginTop: "10px", marginBottom: "20px"}}>{data?.data?.scriptHead}</h2>
            {
                jsonParser(data?.data?.script)?.map(item=>{
                    if(item?.isQna){
                        return (
                            <>
                            {
                                simpleChat(item?.content)
                            }
                            {
                                item?.details?.map(qnas=>(
                                    qnaChat(qnas)
                                ))
                            }
                            <br/>
                            </>
                        )
                    }else{
                        return simpleChat(item?.content)
                    }
                })
            }
        </div>    
    );
  };
  
  // Component for Notes Section
  const NotesSection = () => {
    return (
        <div style={{textAlign: "left"}}>
            <h2 style={{color:'#253F66', margin:'auto', textAlign: "left", marginTop: "10px", marginBottom: "20px"}}>Coming Soon!!!</h2>
            <b>
                This screen will provide the agents with the ability to make notes and save them at screen level for the enrollment. This can be used for tracking purposes.
            </b>
        </div>
    );
  };
  
  // Component for Help Section
  const HelpSection = () => {
    return (
        <div>
            <h2 style={{color:'#253F66', margin:'auto', textAlign: "left", marginTop: "10px", marginBottom: "20px"}}>Coming Soon!!!</h2>
        </div>
    );
  };

export const CustomMessage = (apiRes) => {
    const [activeSection, setActiveSection] = useState("chat");
    const handleSectionChange = (section) => {
        setActiveSection(section);
      };
    let sectionContent = <ChatSection />;
    if (activeSection === 'chat') {
      sectionContent = <ChatSection data={apiRes?.data}/>;
    } else if (activeSection === 'notes') {
      sectionContent = <NotesSection />;
    } 
    // else if (activeSection === 'help') {
    //   sectionContent = <HelpSection />;
    // }

  return (
    <Box
        sx={{
           bgcolor: '#FFFFFF',
           height: "100%",
           display:"flex",
           overflow:"none",
           flexDirection:"column",
           justifyContent: "space-between",
           padding: 0           
        }}
    >
            <div style={{width: "100%", display: "flex", justifyContent: "space-between",  backgroundColor: "#FFF", padding: "30px 15px 10px 15px", alignItems: "center"}}>
                <img src='/images/logo.svg' height={"27px"} width={"114px"} />
                <AvatarRenderer />
            </div>
            <List style={{ overflowY: 'auto', height: "-webkit-fill-available" }}>
                <div style={{padding:'11px'}}>
                    {sectionContent}
                </div>
            </List>
          
            <Box style={{flexShrink:'0'}}>
                <Grid container justifyContent="space-around" alignItems="center" style={{borderTop:'1px solid #B4D6FF', marginTop: '10px', gap: '0px', position: "relative", bottom: 0, clear: "both"}}>
                    <Grid item>
                    <IconButton onClick={() => handleSectionChange('chat')} color={activeSection === 'chat' ? tabColors.light : tabColors.dark}>
                        {activeSection === 'chat' ? Script(tabColors.light) : Script(tabColors.dark)}
                    </IconButton>
                    <Typography variant="subtitle2" align="center" color={activeSection === 'chat' ? tabColors.light : tabColors.dark}>
                        Script
                    </Typography>
                    </Grid>
                    <Grid item>
                    <IconButton onClick={() => handleSectionChange('notes')} color={activeSection === 'notes' ? tabColors.light : tabColors.dark}>
                        {activeSection === 'notes' ? Notes(tabColors.light) : Notes(tabColors.dark)}
                    </IconButton>
                    <Typography variant="subtitle2" align="center" color={activeSection === 'notes' ? tabColors.light : tabColors.dark}>
                        Notes
                    </Typography>
                    </Grid>
                    <Grid item>
                    <IconButton onClick={() =>window.open('https://titangasandpower.sharepoint.com/sites/Training/SitePages/CleanSky%20Intelligence.aspx', '_blank')} color={activeSection === 'help' ? tabColors.light : tabColors.dark}>
                        {activeSection === 'help' ? Help(tabColors.light) : Help(tabColors.dark)}
                    </IconButton>
                    <Typography variant="subtitle2" align="center" color={activeSection === 'help' ? tabColors.light : tabColors.dark}>
                        Help
                    </Typography>
                    </Grid>
                </Grid>
                
            </Box>
        </Box>
  );
};

export const CustomHeader = () => {
  return (
    <div>
    <img src='/images/logo.svg' alt="Your Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
    <span>Your Chatbot Title</span>
  </div>
  );
};

