export default function getStatusByCode(code){
    const mappings = [
        {
            key: '30.E08',
            text: 'Success',
          },
          {
            key: '10.E01',
            text: 'Pending',
          },
          {
            key: '10.E02',
            text: 'Export to excel',
          },
          {
            key: '20.E01',
            text: 'Deposit Required',
          },
          {
            key: '30.E09',
            text: 'fraud',
          },
          {
            key: '40.E04',
            text: 'enrollment_completed',
          }
    ]

    for (let i = 0; i < mappings.length; i++) {
        if (mappings[i].key === code) {
            return mappings[i].text;
        }
    }

    return "Key not found";
}