import { useEffect, useState } from "react";
import DropZon from "./DropZon";
import { fetchUploadedDocuments } from "../../../../api/api";
import FraudDocDropZone from "./FraudDocDropZone";
import { Button } from "../../../styles/Button.styled";
import { useNavigate } from "react-router";

const DepositWaiverDocUpload = ({additional, enrollmentTokens, selectedPlans, showFinalPage,autopayRequired}:{
    additional: any;
    enrollmentTokens: any;
    selectedPlans: any;
    showFinalPage?: any;
    autopayRequired?:any;
}) => {

    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(()=>{
        (async()=>{
            try{
                // await fetchDocs();    
            }catch(err){
                setUploadComplete(false);
            }
        })()
    },[enrollmentTokens?.productId])

    const fetchDocs = async() =>{
        setLoading(true)
        try{
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            const res = await fetchUploadedDocuments(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam);
            if(Number(res.response?.length) > 0){
                setUploadComplete(true)
            }
        }catch(err){
            setUploadComplete(false)
        }
        setLoading(false)
    }

    return(
        <>
        
            <div className="main-div">
                <span className="upload-files-label">UPLOAD FILES</span>
                <span className="upload-files-sublabel">
                <p>
                    Your enrollment with CleanSky Energy is on hold pending receipt and review of your Deposit Alternative documents.  Please upload one of the following documents.<br/>
                </p>
                <p>
                    <b>Letter of credit from a previous electric provider</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant:
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px'}}>
                        <li>Has been a customer of any retail electric provider or an electric utility within the two years prior to the request for electric service</li>
                        <li>Is not delinquent in payment of any such electric service account and</li>
                        <li>During the last 12 consecutive months of service was not late in paying a bill more than once.</li>
                    </ul>
                </p>
                <p>
                    <b>65 or Older</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant is 65 years of age or older and the customer is not currently delinquent in payment of any electric service account.
                </p>
                <p>
                    <b>Victim of Family Violence</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant has been determined to be a victim of family violence as defined in the Texas Family Code $71.004, by a family violence center as defined in Texas Human Resources Code $51.002, by treating medical personnel, by law enforcement personnel, by the Office of a Texas District Attorney or County Attorney, by the Office of the Attorney General, or by a grantee of the Texas Equal Access to Justice Foundation. This determination shall be evidenced by submission of a certification letter from the Texas Council of Family Violence. The certification letter may be submitted directly to CleanSky Energy by faxing a copy to 1-800-681-1950 toll-free.
                </p>
                </span>
                <div className="dropzone-section">
                    {
                        loading ? <div style={{textAlign: "center"}} >Loading...</div> : (
                            uploadComplete ? <div style={{color:"green", textAlign: "center"}} >Thank you, we will now use the documents provided to verify your enrollment.  Once verified, you will receive an email with instructions to complete your enrollment.</div> : <FraudDocDropZone fetchDocs={fetchDocs} docFor="waiver"/>
                        )
                    }
                </div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                        <Button
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            Pay Deposit
                        </Button>
                    </div>
            </div>
            <style>
                {`
                    .main-div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .upload-files-label {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32.68px;
                    }
                    .upload-files-sublabel {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        text-align: left;
                        margin-top: 26px;
                        max-width: 544px;
                        width: 100%;
                    }
                    .dropzone-section {
                        margin-top: 40px;
                        max-width: 544px;
                        width: 100%;
                        padding: 30px;
                        background-color: #EFF7FA;
                        border-radius: 12px;
                    }

                    @media (max-width: 768px) {
                        .main-div {
                            padding: 0 20px;
                        }
                        .upload-files-label {
                            font-size: 20px; /* smaller font size for tablet */
                        }
                        .upload-files-sublabel {
                            width: 100%;
                        }
                        .dropzone-section{
                            width: 100%;
                        }
                    }

                    @media (max-width: 480px) {
                        .upload-files-label {
                            font-size: 20px; /* even smaller font size for mobile */
                        }
                    }
                `}
            </style>
        </>
    )
}

export default DepositWaiverDocUpload;