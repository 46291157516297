import { Modal } from "react-bootstrap"
import { Button } from "./styles/Button.styled";
import { ErrorModalBody } from "./styles/Enrollment.styled";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const UtilityWarningModal = ({
    show,
    content,
    handleSkip,
    setShow,
    setActiveKey
}: {
    show:boolean;
    content: any;
    handleSkip: Function;
    setShow: Function;
    setActiveKey: Function;
}) => {
    const { t } = useTranslation();
    const [notSelected,setNotSelected] = useState("");
    useEffect(()=>{
        if(content.length>0){
            if (content[0].commodity_name == "Gas"){
                setNotSelected("Electric")
            }else{
                setNotSelected("Gas")
            } 
        }
    },[show])
    return (
        <Modal size="lg" centered animation={false} show={show} onHide={() => setShow(false)}>
            <Modal.Body>
                <ErrorModalBody>
                        <p style={{fontSize:"20px"}}>{`Please select your ${notSelected} Plan`}</p> 
                        <div style={{display:"flex",width:"100%",justifyContent:"space-around"}}>
                            <Button onClick={() => {setShow(false); setActiveKey(notSelected)}}>{content.buttonText ? content.buttonText : t('Select Plans')}</Button>
                            <Button onClick={()=>handleSkip()}>{content.buttonText ? content.buttonText : t('Skip')}</Button>
                        </div>
                </ErrorModalBody>
            </Modal.Body>
        </Modal>
    )
}

export const CustomAddressModal = ({
    show,
    setShow,
    handleYes
}: {
    show:boolean;
    setShow: Function;
    handleYes: Function;
}) => {
    const { t } = useTranslation('enrollment');
    const [notSelected,setNotSelected] = useState("");

    return (
        <Modal size="lg" centered animation={false} show={show} onHide={() => setShow(false)}>
            <Modal.Body>
                <ErrorModalBody>
                        <p style={{fontSize:"20px"}}>{t(`premiseNotAvailable`)}</p> 
                        <div style={{display:"flex",width:"100%",justifyContent:"space-around"}}>
                            <Button onClick={() => {setShow(false)}}>{t (`Cancel`)}</Button>
                            <Button onClick={()=>handleYes()}>{t(`Yes`)}</Button>
                        </div>
                </ErrorModalBody>
            </Modal.Body>
        </Modal>
    )
}