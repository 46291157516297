import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined, FileOutlined, CloseCircleFilled } from "@ant-design/icons";
import "./dropzone.css";
import { documentUploader } from "../../../../api/api";
 
const { Dragger } = Upload;
const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
   
    },
    onDrop(e) {
      return e.dataTransfer.files;
    },
 
 
 
   
    // Add the itemRender prop here
    itemRender(originNode, file, fileList, actions) {
 
      return (
          <>
          <div className="upload-bar">
            <div className="sub-upload-bar">
                <FileOutlined />
                <span className="file-name">{file.name}</span>
            </div>
            <CloseCircleFilled className="close-icon" style={{fontSize:'20px', marginLeft:'8%'}} onClick={() => actions.remove(file)} />
          </div>
 
          </>
      );
    },
 
  };
function DropZon({fetchDocs}) {
  // Create a state variable for the fileList and initialize it as an empty array
  const [fileList, setFileList] = useState([]);
 
  // Update the fileList state variable when the files change
  const handleChange = (info) => {
    setFileList(info.fileList);
  };
 
  // function to handle the file submission
  const handleSubmit = async() => {
    // Add a conditional check before calling the forEach method
    // You can use the optional chaining operator (?.) or the logical AND operator (&&)
    try{
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const signParam = url.searchParams.get('sign');
 
        const res = await documentUploader(fileList, idParam, signParam)
        if(res?.status == 200){
            setFileList([]);
            await fetchDocs();
            message.success({
                content: 'documents uploaded successfully',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
        }
    }catch(err){
        console.log('Data in err ==>', err);
        message.error({
            content: 'failed to upload documents. Please try again.',
            style: {
                position: 'absolute',
                width: "100%",
                top: "30px"
            },
        });
    }

  };
 
  return (
    <div className="file-uploader">
      <Dragger
      {...props}
        name="file"
        multiple={true}
        onChange={handleChange}
        style={{padding:'20px', background:'white'}}
        fileList={fileList}
      >
            <p className="ant-upload-drag-icon">
            <UploadOutlined />
            </p>
            <p className="ant-upload-text" style={{color:'#254066'}}>Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            band files.
            </p>
      </Dragger>
      <button className="send-button" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
}
 
export default DropZon;