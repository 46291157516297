import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Card } from 'antd';
import { Button } from '../../styles/Button.styled';
import { useLocation } from 'react-router';
import moment from 'moment';
import { getReceiptData } from '../../../api/api';
import { Trans, useTranslation } from "react-i18next";

export const ReceiptDownload = () => {
  const componentRef = useRef(null);
  const location = useLocation();
  const { t, i18n } = useTranslation('enrollment')
//   const installmentData= location?.state?.case === "full" ? [location?.state?.depositConfig?.deposit_suggested_amount] : location?.state?.depositConfig?.deposit_installment_amount// array
  const enrollmentTokens = location?.state?.enrollmentTokens;

  const [receiptData, setReceiptData] = useState({});

  const url = new URL(window.location.href);
  const idParam = url.searchParams.get('id');
  const idSign = url.searchParams.get('sign');

  useEffect(()=>{
    (async()=>{
        try{
            let res = await getReceiptData(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign)
            setReceiptData(res);
        }catch(err){

        }
    })()
  },[])
  const handleDownloadPDF = () => {
    if (!componentRef.current) return;

    html2canvas(componentRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 size
      const imgHeight = canvas.height * imgWidth / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(t('CleanSky Energy Deposit Receipt.pdf'));
    });
  };

  return (
    <div>
        <div ref={componentRef}>
            <div style={{ maxWidth: '600px', margin: '20px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <Card title={t(`Deposit Payment Receipt`)}>
                    <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <br/>
                    <img src="images/logo.png" alt="Cleansky Logo" height={'60px'} width={'300px'}/>
                    <br/>
                    <br/>
                    <h2>{t('Deposit Payment Receipt')}</h2>
                    <p style={{ fontSize: '0.9em' }}><strong>{t(`Receipt No`)}:</strong> #{receiptData?.receipt_number || "-"}</p>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div style={{ flex: '0 0 32%' }}>
                    <p><strong>{t(`Customer Name`)}:</strong></p>
                    <p>{receiptData?.first_name || ""} {receiptData?.last_name || ""}</p>
                    </div>
                    <div style={{ flex: '0 0 32%' }}>
                    <p><strong>{t(`Transaction Date`)}:</strong></p>
                    <p>{moment(receiptData?.transaction_date)?.format("MM/DD/YYYY")}</p>
                    </div>
                    <div style={{ flex: '0 0 32%' }}>
                    <p><strong>{t(`Amount`)}:</strong></p>
                    <p>${Number(receiptData?.paid_amount).toFixed(2)}</p>
                    </div>
                    </div>
                    <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                    <p><strong>{t(`Total Amount Deposited`)}:</strong> ${Number(receiptData?.paid_amount).toFixed(2)}</p>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                    {Number(receiptData?.suggested_amount)-Number(receiptData?.paid_amount) > 0 ? <p>{t(`receipthead1partial`)}</p> : <p>{t(`receipthead1full`)}</p>}
                    {Number(receiptData?.suggested_amount)-Number(receiptData?.paid_amount) > 0 && <p>{t(`receipthead2`, {amount: Number(Number(receiptData?.suggested_amount)-Number(receiptData?.paid_amount)).toFixed(2)})}</p>}
                    <p>{t(`receipthead3`)}</p>
                    </div>
                    <p><strong>{t(`Total Deposit Amount`)}:</strong> ${Number(receiptData?.suggested_amount).toFixed(2)}</p>
                    <div style={{ background: '#f0f8ff', padding: '10px', borderRadius: '5px', marginTop: '20px' }}>
                    <h3>{t(`Installments`)}</h3>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr>
                    <th style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>{t(`Description`)}</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>{t(`Amount`)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* {installmentData.map((amount, index) => (
                        <tr key={index}>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>{index === 0 ? "Amount Paid" : `Installment ${index + 1}`}</td>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>${amount}</td>
                        </tr>
                    ))} */}
                    <tr>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>{t(`Amount Paid`)}</td>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>${Number(receiptData?.paid_amount).toFixed(2)}</td>
                    </tr>
                    {Number(receiptData?.suggested_amount)-Number(receiptData?.paid_amount) > 0 && <tr>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>{t(`Remaining Deposit`)}</td>
                        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'left' }}>${Number(Number(receiptData?.suggested_amount)-Number(receiptData?.paid_amount)).toFixed(2)}</td>
                    </tr>}
                    </tbody>
                    </table>
                    </div>
                </Card>
            </div>
        </div>
        <div style={{display:"flex",justifyContent:"center", gap: "20px"}}>
            <Button onClick={()=>{window.history.back()}}>{t(`Back`)}</Button>
            <Button onClick={handleDownloadPDF}>{t(`Download PDF`)}</Button>
        </div>
    </div>
  );
}

export default ReceiptDownload;