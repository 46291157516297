export const errorMsg: any = {
    '10.E01': {
        'headline': 'Welcome to CleanSky Energy!',
        'paragraph': 'Your enrolment has been received.  Please wait for an email confirmation from CleanSky including the plan details.  Once approved by your local utility company, you\'ll receive a second email confirming your official start date.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'
    },
    '20.E01': {
        'headline': 'We are almost there!',
        'paragraph': 'Remember to pay your deposit by logging into MyAccount',
        'paragraph2': 'Once your deposit has been satisfied, your enrollment will be processed.',
        'buttonType': 'myaccount'
    },
    '30.E01': {
        'headline': 'We are almost there!',
        'paragraph': 'Remember to pay your deposit by logging into MyAccount',
        'paragraph2': 'Once your deposit has been satisfied, your enrollment will be processed.',
        'buttonType': 'myaccount'
    },
    '30.E02': {
        'headline': 'Welcome to CleanSky!',
        'paragraph': 'Your enrollment request has been received. Please wait for an email confirmation from CleanSky including the plan details and your next steps.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'   
    },
    '30.E03': {
        'headline': 'We are almost there!',
        'paragraph': 'We\'ve received your Letter of Credit (Thank you!) and someone from our team is reviewing it. Please wait for an email confirmation from CleanSky confirming that your enrollment has been processed.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'   
    },
    '30.E04': {
        'headline': 'We are almost there!',
        'paragraph': 'Remember to upload your letter of credit. Once received, our team will review the letter and confirm that your enrollment has been processed.',
        'paragraph2': ''
    },
    '30.E05': {
        'headline': 'Oops!',
        'paragraph': 'Your deposit payment did not go through.  Please login to MyAccount to pay the deposit.  Once paid, your enrollment will be processed.',
        'paragraph2': '',
        'buttonType': 'myaccount'
    },
    '30.E06': {
        'headline': 'We are almost there!',
        'paragraph': 'We\'re still working to process your deposit payment. Please wait for an email confirmation from CleanSky Energy including the plan details. Once approved by your local utility company, you\'ll receive a second email confirming your official start date.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'
    },
    '30.E07': {
        'headline': 'Welcome to CleanSky Energy!',
        'paragraph': 'Your enrolment has been received. Please wait for an email confirmation from CleanSky including the plan details.  Once approved by your local utility company, you\'ll receive a second email confirming your official start date.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'
    },
    '30.E08': {
        'headline': 'Welcome to CleanSky Energy!',
        'paragraph': 'Your enrolment has been received. Please wait for an email confirmation from CleanSky including the plan details. Once approved by your local utility company, you\'ll receive a second email confirming your official start date.',
        'paragraph2': 'Thank you for choosing CleanSky Energy!'
    }
}