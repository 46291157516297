import { useTranslation } from "react-i18next";
import { EnrollmentResultContent, PlanSummaryRow } from "../../styles/Enrollment.styled";
import { Button } from "../../styles/Button.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shareAsaleNew, shareAsaleNew2 } from "../../../utils/shareAsaleNew";
import { useEffect, useState } from "react";
import isAdmin from "../../../utils/isAdmin";
import { getEnrollmentRecord } from "../../../api/enrollment";
import { updateDealHubspot } from "../../../api/api";
import { dealStages } from "../../../config";
import { adminPortalAgent } from "../../../config";

export const Successful = ({ status, selectedPlans, enrollmentTokens, getScriptForAgent }: {
    status: string
    selectedPlans: any
    enrollmentTokens: any
    getScriptForAgent?: any
}) => {
    const { t,i18n } = useTranslation('enrollment');
    const [isAgent,setIsAgent] = useState(false);
    const [state,setState] = useState('')
    
    let allDocs: any = {};
    selectedPlans.forEach((plan: any) => {
        const { commodity_name, docs } = plan;
        if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
        const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
        allDocs[commodity_name] = availableDocs;
    })
    
    
    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setIsAgent(_isAdmin)
            await isAdmin() && getScriptForAgent("7")
            let _res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign)
            shareAsaleNew2(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign, "confirmation" , _res?.response?.service_state)
            updateDealHubspot(enrollmentTokens?.enrollment_id, dealStages.COMPLETED_ENROLLMENT_NO_AUTOPAY_DEPOSIT_NEEDED)
            setState(_res?.response?.service_state)
        })()
    },[])

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                {isAgent?(state=="PA"?
                <p>
                    Your enrollment will become effective within the next 10 days or according to your utility’s scheduled timeframe for initiating service. Your confirmation number is E as in Echo | N as in November | L as in Lima | 6-digit date | 3-digit Extension|
                </p>:
                <p>
                    Your enrollment will become effective with the next available meter read date or according to your utility’s scheduled timeframe for initiating service. This typically can take up to two billing cycles, or 15-45 days as per the utility rules.  Your confirmation number is E as in Echo | N as in November | L as in Lima | 6-digit date | 3-digit Extension|
                </p>):<p>{t('Your enrollment request is now processing and may take up to 48 hours to completed. Your will receive an email when your status has changed.')}</p>}
                <p>{t('If you any questions please call')} <a href="tel:(888)733-5557">(888)733-5557</a> {t('or email')} <a href="mailto:customercare@cleanskyenergy.com">customercare@cleanskyenergy.com</a></p>
                <div className="mt-5">
                    { isAgent?
                    <Button
                        withArrow
                        type='button'
                        onClick={() => {
                            window.location.href = adminPortalAgent
                        }}>
                        {t('Back to Enrollment Portal')}
                    </Button>:
                        <Button
                        withArrow
                        onClick={() => {
                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                        }}>
                        {t('Back to Homepage')}
                    </Button>}
                </div>
                <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5">
                    <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                    {
                        Object.keys(allDocs).map((type: string, typeIdx: number) => {
                            return (
                                <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                    <h6>{t(`Documents for ${type} Plan`)}</h6>
                                    <div className="plan-links mt-3">
                                        {
                                            allDocs[type].map((doc: any, idx: number) => (
                                                <PlanSummaryRow narrow key={idx} className="text-start">
                                                    <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                        <FontAwesomeIcon icon="file-pdf" />
                                                        <span className="ms-2">{doc.display_name}</span>
                                                    </a>
                                                </PlanSummaryRow>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </EnrollmentResultContent>
    )
}
