import { useEffect, useState } from "react";
import DropZon from "./DropZon";
import { fetchUploadedDocuments } from "../../../../api/api";

const DocUploadScreen = () => {

    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        (async()=>{
            try{
                await fetchDocs();    
            }catch(err){
                setUploadComplete(false);
            }
        })()
    },[])

    const fetchDocs = async() =>{
        setLoading(true)
        try{
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            const res = await fetchUploadedDocuments(idParam, signParam);
            if(Number(res.response?.length) > 0){
                setUploadComplete(true)
            }
        }catch(err){
            setUploadComplete(false)
        }
        setLoading(false)
    }

    return(
        <>
            <div className="main-div">
                <span className="upload-files-label">UPLOAD FILES</span>
                <span className="upload-files-sublabel">Please note that your enrollment has an Active Switch Hold on the enrollment.<br/> Please provide the relevant documents to get the Switch Hold removed.</span>
                <div className="dropzone-section">
                    {
                        loading ? <div style={{textAlign: "center"}} >Loading...</div> : (
                            uploadComplete ? <div style={{color:"green", textAlign: "center"}} >Your documents are uploaded successfully!</div> : <DropZon fetchDocs={fetchDocs}/>
                        )
                    }
                </div>
            </div>
            <style>
                {`
                    .main-div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .upload-files-label {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32.68px;
                    }
                    .upload-files-sublabel {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        text-align: center;
                        margin-top: 26px;
                        max-width: 544px;
                        width: 40%;
                    }
                    .dropzone-section {
                        margin-top: 40px;
                        max-width: 544px;
                        width: 40%;
                        padding: 30px;
                        background-color: #EFF7FA;
                        border-radius: 12px;
                    }

                    @media (max-width: 768px) {
                        .main-div {
                            padding: 0 20px;
                        }
                        .upload-files-label {
                            font-size: 20px; /* smaller font size for tablet */
                        }
                        .upload-files-sublabel {
                            width: 100%;
                        }
                        .dropzone-section{
                            width: 100%;
                        }
                    }

                    @media (max-width: 480px) {
                        .upload-files-label {
                            font-size: 20px; /* even smaller font size for mobile */
                        }
                    }
                `}
            </style>
        </>
    )
}

export default DocUploadScreen;