import React, { useEffect, useState } from 'react';
import './App.css';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';


function ChatBotAgent({scriptData}: {
    scriptData:any
}) {
  const [showBot, toggleBot] = useState<boolean>(true);
  const [key, setKey] = useState(0);
  const { scriptHead, script, dynamicData } = scriptData;

  const buttonStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '10px',    // Adjust this value to position the button vertically
    right: '10px',     // Adjust this value to position the button horizontally
    padding: '10px',
    zIndex: 999,
    height: "60px",
    width: "60px",
    color: "white"
  };

  const chatbotStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '50px',    // Adjust this value to position the chatbot vertically
    right: '10px',     // Adjust this value to position the chatbot horizontally
    maxWidth: '600px',
    minWidth:"440px",
    zIndex: 999
  };


  useEffect(()=>{
    setKey(prevKey => prevKey + 1);
  },[script])

  return (
    <div className="App">
      {showBot && (
        <div style={chatbotStyle} key={key}>
            <Chatbot
                // headerText={<div></div>}
                config={config(scriptData || [])}
                actionProvider={ActionProvider}
                messageParser={MessageParser}
            />
            <style>{`
                .react-chatbot-kit-chat-header{
                    display: none;
                }
                .react-chatbot-kit-chat-container{
                    min-width: 570px,
                    background: #FAFEFF;
                    box-shadow:6px -6px 25px 0px rgba(159, 197, 255, 0.25), -6px 6px 25px 0px rgba(159, 197, 255, 0.25);
                }
                .react-chatbot-kit-chat-bot-message{
                    margin-left: 0;
                    width: auto;
                    color: #284168;
                }
                .react-chatbot-kit-chat-input-form{
                    display: none
                }
                .react-chatbot-kit-chat-message-container{
                    overflow: hidden;
                    padding: 0;
                    height: 570px
                }
                .react-chatbot-kit-chat-header{
                    font-size: 16px;
                    color: #284168;
                    background-color: #EFF7FA
                }
                .react-chatbot-kit-chat-inner-container{
                    border: 2px solid #EFF7FA;
                    height: auto;
                    width: 440px;
                }
                .react-chatbot-kit-chat-container {
                  width: 100%;
              }
            `}</style>
        </div>
      )}
      {/* <div style={{...buttonStyle, backgroundColor: "#284168", borderRadius: "100%", cursor: "pointer"}}> */}
        {/* <SupportAgentOutlinedIcon style={buttonStyle} onClick={() => toggleBot((prev) => !prev)}/> */}
      <div style={{...buttonStyle,width:"44px",height:"44px",background:"#27B6EA",borderRadius:"8px",display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}} onClick={() => toggleBot((prev) => !prev)}>
        {showBot?
        <img src='/images/ChatClose.svg' />:
        <img src='/images/Chat.svg' />}
      </div>
    </div>
  );
}

export default ChatBotAgent;
