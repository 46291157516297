import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { EnrollmentContext } from "../../../enrollment";
import { dealStages, iCheckConfig } from "../../../config";
import { Button } from "../../styles/Button.styled";
import { EnrollmentResultContent, PlanSummaryRow } from "../../styles/Enrollment.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { FormLoading } from "../../form-loading";
import { getEnrollmentRecord, saveDebtPaymentInfo, saveEnrollmentPaymentInfo } from "../../../api/enrollment";
import { signEncoder } from "../../../utils/signEncoder";
import moment from 'moment';
import { checkExistingCustomer, paidCustomer, uiLogging, updateDealHubspot } from "../../../api/api";
import hubspotApiCalls from "../../../utils/hubspotApiCalls";
import ShareAsale from "../../../utils/ShareAsale";
import { shareAsaleNew } from "../../../utils/shareAsaleNew";
import selectedPlanMagicLink from "../../../utils/selectedPlanMagicLink";
import urlHasParam from "../../../utils/urlHasParam";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const DebtPayScreen = () => {
    
    const { t, i18n } = useTranslation('enrollment')
    const [ paymentMethod, setPaymentMethod ] = useState('1');
    const [ showSuccessfulPage, setShowSuccessfulPage ] = useState( false);
    const [ paymentResponse, setPaymentResponse ] = useState();
    const [ saving, setSaving ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ showDepositWaiverPopup, setShowDepositWaiverPopup ] = useState(false);
    const [ showFinalPage, setShowFinalPage ] = useState(false);
    // const { handleSetErrorModalContent } = useContext<any>(EnrollmentContext);
    const [ depositAmount, setDepositAmount] = useState("0")
    const [ newNameData, setNewNameData ] = useState({
        firstName: "",
        lastName: ""
    })
    const [ selectedPlansML, setSelectedPlansML ] = useState<any>()
    const navigate = useNavigate();
    // const depositAmount = additional && additional[0] && additional[0].value ? additional[0].value : 0;
    const url = new URL(window.location.href);
    const idName = url.searchParams.get('name');


    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessage);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessage);
            }
        }
    }, [])

    function removeParamsFromUrl(url: string) {
        const urlObj = new URL(url);
        const paramsToRemove = ["name", "status", "isTx"];
    
        for (const paramName of paramsToRemove) {
            urlObj.searchParams.delete(paramName);
        }
    
        return urlObj.toString();
    }

    useEffect(()=>{
        (async()=>{
            try{
                const urlSearchParams = new URLSearchParams(window.location.search)
                const is_magic_link = urlSearchParams.has("sign")
                
                const url = new URL(window.location.href);
                const idParam = url.searchParams.get('id');
                const signParam = url.searchParams.get('sign');
    
                let res = await checkExistingCustomer(idParam, signParam)
    
                setNewNameData({
                    firstName: res?.first_name,
                    lastName: res?.last_name
                })

                let amt = 0;
                res?.response?.prior_customer?.map((item:any)=>{
                    if(Number(item?.AR_AMT)>0){
                        amt = amt+ Number(item?.AR_AMT);
                    }else if(Number(item?.WRITE_OFF_AR_AMT)<0){
                        amt = amt+ Number(item?.WRITE_OFF_AR_AMT);
                    }
                })

                setDepositAmount(String(amt))
    
                if(idParam){
                    let dataFetched = await selectedPlanMagicLink(idParam, signParam)
                    setSelectedPlansML(dataFetched)
                }
    
                if(!showFinalPage){
                    if(is_magic_link){
                        await hubspotApiCalls(idParam, signParam, "fraud_whitelisted_deposit_visited", removeParamsFromUrl(window.location.href))
                        await uiLogging(idParam, signParam, `Hubspot API call - Fraud whitelisted deposit visited` )
                    }
                    //UPDATE DEAL STAGE HERE
                    updateDealHubspot(idParam, dealStages.ABANDON_DEPOSIT)
                }

                setLoading(false)
            }catch(err){
                setLoading(false)
            }
        })()
    },[])

    const saveRecords: any = async (enrollmentId: string, jsonData: string, sign: string, confirmationNumber: string, attempt = 1, maxAttempts = 3) => {
        const res = await saveDebtPaymentInfo(enrollmentId, jsonData, sign, confirmationNumber);
        if (res && parseInt(res.status) === 1) {
            await uiLogging(enrollmentId, sign, `DEBT PAY - Transaction details successfully entered on our database`)
            setSaving(false);
            return res;
        }
        else if (res && parseInt(res.status) === 2) {
            await uiLogging(enrollmentId, sign, `DEBT PAY - Transaction details entry FAILED on our database - status 2 from response`)
            setSaving(false);
            return res;
        }
        else if (attempt < maxAttempts) {
            await uiLogging(enrollmentId, sign, `DEBT PAY - Transaction details entry on our database - Attempt ${attempt}`)
            setSaving(false);
            return saveRecords(enrollmentId, jsonData, sign, confirmationNumber, attempt + 1, maxAttempts);
        }
        else {
            await uiLogging(enrollmentId, sign, `DEBT PAY - Transaction details entry FAILED on our database - Network Error`)
            setSaving(false);
            throw new Error((res && res.message) ? t(res.message) : t('Network Error'));
        }
    }

    const handleMessage = async(e: any) => {
        const jsonData = e.data;
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const signParam = url.searchParams.get('sign');
        if (typeof jsonData !== 'undefined') {
            if (typeof jsonData === 'object' && jsonData.constructor.name === 'Object') {
                const { source } = jsonData;
                if (typeof source !== 'undefined' && source === 'iCheck') {
                    if (typeof jsonData.error !== 'undefined' && jsonData.error !== null) {
                        const url = new URL(window.location.href);
                        const idParam = url.searchParams.get('id');
                        const signParam = url.searchParams.get('sign');
                        await uiLogging(idParam, signParam, `DEBT PAY - ICG response has error, modal shown to user for ${jsonData?.error}`)
                    }
                    else {
                        setPaymentResponse(jsonData);
                        setSaving(true);
                        const { transactionResponse } = jsonData;
                        const confirmationNumber = transactionResponse.split('|')[7];
                        if (transactionResponse.indexOf('APPROVED') >= 0) {
                            
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');
                            
                            await uiLogging(idParam, signParam, `DEBT PAY - credit card transaction APPROVED from ICG, starting process to enter data in our database`)
                            saveRecords(
                                idParam,
                                JSON.stringify(jsonData),
                                signParam,
                                confirmationNumber
                            ).then(async () => {
                                // console.log(res)
                                setSaving(false);
                                setShowSuccessfulPage(true);
                            })
                        }
                        else {
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');
                            await uiLogging(idParam, signParam, `DEBT PAY - Error in processing credit card, modal shown to user for ${transactionResponse?.split('|')[0]}`)
                            setSaving(false);
                        }
                    }
                }
            }
        }
    }

    const getAdditionalContent = () => {
        const {
            ccUrl, baUrl, ccSpUrl, baSpUrl,
            appId, appSecret, styles
        } = iCheckConfig;
        const url = paymentMethod === '1' ?
                    (i18n.language === 'es' ? ccSpUrl : ccUrl) :
                    (i18n.language === 'es' ? baSpUrl : baUrl);

        const firstName = newNameData.firstName; 
        const lastName = newNameData.lastName;

        const currentUrl = new URL(window.location.href);
        const idParam = currentUrl.searchParams.get('id');

        return (
            <div style={{ marginTop: 12 }}>
                <p style={{ fontSize: 18 }}><b>{t('Deposit Amount')}: <span style={{ fontSize: 20 }}>${depositAmount}</span></b></p>
                {/* <div className="mb-3">
                    <a href="javascript:;" style={{color:'#26B7EA',fontSize:17}} onClick={() => setShowDepositWaiverPopup(true)}>{t('Require a deposit alternative?')}</a>
                </div> */}
                {/* <div style={{textAlign:'center',marginBottom:24}}>
                    <div className="d-flex justify-content-center">
                        <CheckboxItem label="Credit Card" value="1" checked={paymentMethod === '1'} isRadio disabled={false} handleClick={() => setPaymentMethod('1')} />
                        <CheckboxItem label="eCheck (ACH)" value="2" checked={paymentMethod === '2'} isRadio disabled={false} handleClick={() => setPaymentMethod('2')} />
                    </div>
                </div> */}
                <div className="d-flex justify-content-center">
                    <div className="position-relative">
                        <iframe
                            id="iFrameICG"
                            name="iFrameICG"
                            title="iFrameICG"
                            src={`${url}?appId=${appId}&appSecret=${appSecret}&custId=${`${encodeURIComponent(firstName)}${encodeURIComponent(lastName)}_${moment().format('YYYYMMDDHHmmss')}`}&amount=${depositAmount}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&custom1=${idParam}&css=${styles}`}
                            frameBorder="0"
                            scrolling="no"
                            width="360"
                            height="560"
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (showSuccessfulPage) {
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const idSign = url.searchParams.get('sign');
        uiLogging(idParam, idSign, "DEBT PAY - Reached Thank you page")
        
        return (
            <div className="text-center">
                <h2 className="mb-3">{t('Thank you for your payment')}</h2>
                <h3 className="mb-3">{t('Please continue to complete your enrollment with agent')}</h3>
            </div>
        )
    }
    return (
        <div>
            <EnrollmentResultContent>
                <div className="text-center">
                    <h1 className="mb-3">{t("We're Almost There!")}</h1>
                    <p>{t('Thank you. To continue your enrollment, please pay your debt deposit by providing your Credit Card information and selecting Continue below.')}</p>
                    {
                        loading ? <div className='d-flex align-items-center mb-4 mt-4 justify-content-center'>
                            <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Loading</div>
                            <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/>
                        </div> : <div className="mt-5">{getAdditionalContent()}</div>
                    }
                </div>
                {/* <div className="mt-5">
                    <h5 className="mb-2">{t('Why Should I Pay The Deposit?')}</h5>
                    <p>{t('After review of your credit history, a refundable deposit is required to initiate service. Your payment information will only be used to process the deposit and will not be used for any other purposes.')}</p>
                </div> */}
            </EnrollmentResultContent>
            <Modal id="deposit-waiver-modal" size="lg" centered show={showDepositWaiverPopup} onHide={() => setShowDepositWaiverPopup(false)}>
                <Modal.Header closeButton>
                    <h4 className="pt-3">{t('Alternatives to Paying a Deposit')}</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine1">
                            We may be able to waive your deposit if you qualify under any of the following, please call us at <a href="tel:8887335557">(888) 733-5557</a> to learn more about these options.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine2" components={{2: <b />}}>
                            <b>Letter of credit from a previous electric provider</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant:
                        </Trans>
                    </p>
                    <ul>
                        <li>{t('Has been a customer of any retail electric provider or an electric utility within the two years prior to the request for electric service;')}</li>
                        <li>{t('Is not delinquent in payment of any such electric service account; and')}</li>
                        <li>{t('During the last 12 consecutive months of service was not late in paying a bill more than once.')}</li>
                    </ul>
                    <br />
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine3" components={{3: <b />}}>
                            <b>65 or Older</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant is 65 years of age or older and the customer is not currently delinquent in payment of any electric service account.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine4" components={{4: <b />, 6: <a href="tel:1-800-681-1950" />}}>
                            <b>Victim of Family Violence</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant has been determined to be a victim of family violence as defined in the Texas Family Code §71.004, by a family violence center as defined in Texas Human Resources Code §51.002, by treating medical personnel, by law enforcement personnel, by the Office of a Texas District Attorney or County Attorney, by the Office of the Attorney General, or by a grantee of the Texas Equal Access to Justice Foundation. This determination shall be evidenced by submission of a certification letter from the Texas Council of Family Violence. The certification letter may be submitted directly to CleanSky Energy by faxing a copy to <a href="tel:1-800-681-1950">1-800-681-1950</a> toll-free.
                        </Trans>
                    </p>
                    {/* <p>
                        <Trans i18nKey="enrollment:depositWaiverLine5" components={{5: <b />}}>
                            <b>Letter of Guarantee</b> – The guarantor must be a current CleanSky Energy customer and remain a customer for the same term as the applicant, have no past due balance, and have no more than one late payment in a 12-month period during the term of the guarantee agreement.
                        </Trans>
                    </p> */}
                </Modal.Body>
            </Modal>
            <FormLoading show={saving} />
        </div>
    )
}
