import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { CheckboxItemProps } from '../types'

export const CheckboxItem: React.FC<CheckboxItemProps> = ({
    label,
    value,
    checked,
    handleClick,
    onlyread,
    disabled,
    isRadio,
    full,
    error,
    id
}) => {
    if (value === '0') return null;
    return (
        <div
            className={`checkbox-item ${onlyread ? 'onlyread' : ''} ${full ? 'full' : ''}`}
            onClick={() => { if (!disabled && !onlyread) handleClick(value); }}
            data-test-id={id ? id : ''}
        >
            {
                checked ?
                <FontAwesomeIcon icon={isRadio ? "check-circle" : "check-square"} style={{color:'#FFAF19',fontSize:20}} /> :
                <FontAwesomeIcon icon={isRadio ? "circle" : "square"} style={{color:'#ddd',fontSize:20}} />
            }
            <span className={error ? 'error' : ''}>{label}</span>
        </div>
    )
}
export const CheckboxItem2: React.FC<CheckboxItemProps> = ({
    label,
    value,
    checked,
    handleClick,
    onlyread,
    disabled,
    isRadio,
    full,
    error,
    id
}) => {
    if (value === '0') return null;
    return (
        <div
            className={`checkbox-item ${onlyread ? 'onlyread' : ''} ${full ? 'full' : ''}`}
            onClick={() => { if (!disabled && !onlyread) handleClick(value); }}
            data-test-id={id ? id : ''}
            style={{display:"flex",alignItems:"flex-start"}}
        >
            {
                checked ?
                <FontAwesomeIcon icon={isRadio ? "check-circle" : "check-square"} style={{color:'#FFAF19',fontSize:20}} /> :
                <FontAwesomeIcon icon={isRadio ? "circle" : "square"} style={{color:'#ddd',fontSize:20}} />
            }
            <span className={error ? 'error' : ''}>{label}</span>
        </div>
    )
}

export const CheckboxItemNew: React.FC<CheckboxItemProps> = ({
    label,
    value,
    checked,
    handleClick,
    onlyread,
    disabled,
    isRadio,
    full,
    error,
    id
}) => {
    if (value === '0') return null;
    return (
        <div
            onClick={() => { if (!disabled && !onlyread) handleClick(value); }}
            data-test-id={id ? id : ''}
            style={{
                display: "flex",
                cursor: "pointer",
                margin: "20px 0",
                flex: "0 0 50%",
                overflow: "hidden",
            }}
        >
            {
                checked ?
                <FontAwesomeIcon icon={isRadio ? "check-circle" : "check-square"} style={{color:'#FFAF19',fontSize:20}} /> :
                <FontAwesomeIcon icon={isRadio ? "circle" : "square"} style={{color:'#ddd',fontSize:20}} />
            }
            <span className={error ? 'error' : ''} style={{marginLeft: "10px"}}><div dangerouslySetInnerHTML={{ __html: label }} /></span>
        </div>
    )
}