import styled from 'styled-components';

interface ButtonProps {
    secondary?: boolean;
    sm?: boolean;
    full?: boolean;
    withArrow?: boolean;
}

export const Button = styled.button<ButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ disabled, secondary }) => disabled ? ( secondary ? '#fff' : 'rgba(0, 0, 0, .12)') : ( secondary ? '#fff' : 'rgb(37, 63, 102)')};
    border-style: solid;
    border-width: ${({ secondary }) => secondary ? '2px' : '3px'};
    border-color: ${({ secondary, disabled }) => secondary ? ( disabled ? '#E6E6E6' : 'rgb(37, 64, 102)' ) : 'transparent'};
    padding: ${({ sm }) => sm ? '8px 12px' : '12px 40px'};
    font-size: ${({ sm }) => sm ? '13px' : '15px'};
    border-radius: 12px;
    font-weight: bold;
    width: ${({ full }) => full ? '100%' : 'auto'};
    color: ${({ secondary, disabled }) => disabled ? ( secondary ? '#E6E6E6' : 'rgba(0, 0, 0, .26)' ) : ( secondary ? 'rgb(37, 64, 102)' : '#fff' )};
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    transition: all .3s;

    &:hover {
        color: ${({ secondary, disabled }) => secondary ? 'rgba(37, 64, 102, .7)' : (disabled ? 'rgba(0, 0, 0, .26)' : 'rgb(37, 64, 102)')};
        background-color: ${({ disabled, secondary }) => disabled ? ( secondary ? '#fff' : 'rgba(0, 0, 0, .12)') : ( secondary ? '#fff' : '#fff')};
        border-style: solid;
        border-color: ${({ secondary }) => secondary ? 'rgba(37, 64, 102, .7)' : 'rgb(37, 64, 102)'};
    }

    ::after {
        display: ${({ withArrow }) => withArrow ? 'inline-flex' : 'none'};
        content: ' ';
        width: 20px;
        height: 20px;
        mask: url('/images/ChevronRight.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: ${({ secondary, disabled, theme }) => disabled ? ( secondary ? '#E6E6E6' : 'rgba(0, 0, 0, .26)' ) : ( secondary ? theme.colors.primary : '#fff' )};
        margin-left: 6px;
        margin-right: -6px;
    }
`