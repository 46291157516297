import React, { useState, useEffect } from 'react';
import { getEnrollmentRecord, saveEnrollment } from '../../../api/enrollment';
import hubspotApiCalls from '../../../utils/hubspotApiCalls';
import urlHasParam from '../../../utils/urlHasParam';
import CircularProgress from '@mui/material/CircularProgress';
import { getAgentScript, getDepositConfig, saveProspectPay, sendMagicLinkEmail, updateStatus } from '../../../api/api';
import { Button } from '../../styles/Button.styled';
import { useLocation, useNavigate } from 'react-router';
import { EnrollmentFormCol } from '../../styles/Enrollment.styled';
import { CheckboxItemNew } from '../../checkbox-item';
import { Trans, useTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';
import { message } from 'antd';
import { adminPortalAgent } from '../../../config';

export const AgentPaymentScreen = ({ purpose, enrollmentTokens, setScriptData,selectedPlans, setSpecialPro, setStep }: {
    purpose: string;
    enrollmentTokens: any;
    selectedPlans: any;
    setScriptData: Function;
    setSpecialPro: Function;
    setStep: any;
}) => {
    const [ isExistingCustomer, setIsExistingCustomer ] = useState(true);
    const [ isMobile, setIsMobile ] = useState(false);
    const [ ssn, setSsn ] = useState("0000-0000-0000-5632");
    const [ userEmail, setUserEmail ] = useState("")
    const [ userName, setUserName ] = useState("")
    const [ dob, setDob ] = useState("10/31/2023");
    const [ sent, setSent ] = useState(false)
    const [ fetchLoader, setFetchLoader ] = useState(false);
    const [ sentLoader, setSentLoader ] = useState(false);
    const [ checkBox, setCheckBox ] = useState(false);
    const [ checkBox2, setCheckBox2 ] = useState(false);
    const [ checkBox3, setCheckBox3 ] = useState(purpose === "deposit" ? false : true);
    const [ depositData, setDepositData ] = useState({});
    const [ showLinkSendSection, setShowLinkSendSection ] = useState(false);
    const [ showDepositWaiverPopup, setShowDepositWaiverPopup ] = useState(false);
    const [ transactionData, setTransactionData ] = useState<any>()
    const { t,i18n} = useTranslation('enrollment')  
    const navigate = useNavigate();
    const location = useLocation();
    const [loader,setLoader] = useState(false)

    const getScriptForAgent = async(script_id: string)=>{
        try{
            let body= {};
                if(enrollmentTokens[0]?.enrollment_id){
                    body = {
                        script_id: script_id,
                        language:i18n.language === 'es' ? 'spanish' : 'english',
                        state: "TX" ,
                        enrollment_id: enrollmentTokens[0]?.enrollment_id,
                        commodity: (selectedPlans.length > 1) ? "gas" : selectedPlans[0]?.commodity_name,
                        utility_code: selectedPlans[0]?.id_utility,
                    }
                }
                else{
                    body = {
                        script_id: script_id,
                        language:i18n.language === 'es' ? 'spanish' : 'english',
                        state:  "TX" ,
                        commodity: (selectedPlans.length > 1) ? "gas" : selectedPlans[0]?.commodity_name,
                        utility_code: selectedPlans[0]?.id_utility,
                    }
                }
            const res = await getAgentScript(body)
            setScriptData({
                //@ts-ignore
                script: res.length>0 ? res[0]?.script_content:"",
                scriptHead: res.length>0 ? res[0]?.screen_name:"",
                dynamicData:{}
            })
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {

        (async()=>{
            await fetchData();
            const _data = await getDepositConfig();
            setDepositData(JSON.parse(_data?.EsiidESO?.ConfigData))
            let res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign)
            let customerData = res?.response;
            setShowLinkSendSection(!Boolean(customerData?.ssndob_via_link))
            const magicLink = removeURLParameter(`${window.location.href}`, "agent_id")
            const newMagicLink = `${magicLink}${urlHasParam(magicLink)?"&":"?"}id=${enrollmentTokens?.enrollment_id}&sign=${enrollmentTokens?.sign}`
            await hubspotApiCalls(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign, purpose == "autopay" ? "autopay_visited" : "deposit_visited", newMagicLink, purpose == "autopay" ? "Agent Autopay Setup Screen" : "Agent Deposit Payment Screen")
        })()

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLinkSubmit = async() =>{
        if(!checkBox) return
        setSentLoader(true)
        try{
            const res = await sendMagicLinkEmail(enrollmentTokens?.enrollment_id, userEmail)
            const response = await res.response;
            if(JSON.parse(response).sendResult === "SENT"){
                setSent(true)
            }
            message.success({
                content: 'Magic Link Email sent Successfully',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
            setSentLoader(false)
        }catch(err){
            console.log("Error while sending msg", err)
            message.error({
                content: 'Failed to send Mail for Magic Link',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
            setSentLoader(false)
        }
    }

    const handleSubmit = () =>{
        // updateStatus(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign, null, "New-Enrollment-Review", null)
        setStep(3)
        setSpecialPro({
            "ssn": ssn,
            "date_of_birth": dob
        })
        window.scrollTo(0, 0);
        navigate(`/review${location.search}`);
    }

    const fetchData = async () => {
        setFetchLoader(true)
        try{
            let data = await getEnrollmentRecord(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign);
            setSsn(`XXX-XX-${data?.response?.ssn}`)
            setDob(data?.response?.date_of_birth)
            setTransactionData(data?.transaction_record)
            setUserEmail(data?.response?.email)
            setUserName(`${data?.response?.first_name} ${data?.response?.last_name}`)
            if(data?.transaction_record && !Array.isArray(data?.transaction_record)){
                getScriptForAgent("7")
            }
            setFetchLoader(false)
        }catch(err){
            setFetchLoader(false)
            console.log(err)
        }
    }

    function removeURLParameter(url: string, parameterToRemove: string) {
        var regex = new RegExp("[?&]" + parameterToRemove + "=[^&]+", 'i');
        url = url.replace(regex, '');
        url = url.replace(/[?&]$/, '');
        return url;
    }

    function formatDate(input: string): string {
        if (typeof input === 'string' && input.length === 4) {
          const month = input.slice(0, 2);
          const day = input.slice(2);
          if (!isNaN(Number(month)) && !isNaN(Number(day))) {
            return `${month}/${day}`;
          }
        }
        return "XX/XX";
    }
      
    function checkTransactionData(transactionData: any): boolean {
        if (Array.isArray(transactionData)) {
            return false;
        } else if (typeof transactionData === 'object' && transactionData !== null) {
            return true;
        } else {
            return false;
        }
    }

    const sendPaymentScreen = async () =>{
        setLoader(true)
        try{
            await saveProspectPay(enrollmentTokens?.enrollment_id, enrollmentTokens?.sign, "1")
            message.success({
                content: "Payment information sent successfully",
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
            setLoader(false)
        }catch(err){
            setLoader(false)
        }
    }

    const CheckBoxVerbiage = () =>{
        return(
            purpose == "deposit" ?
            <div>
                <CheckboxItemNew
                    label={String("Agent Must Read Verbatim:  Mr./Ms. #CUSTOMERFULLNAME#, after review of your credit history, it has been determined that a refundable deposit of $#DEPOSITAMOUNT# will be required to complete your enrollment.  We may be able to waive your deposit if you qualify under certain conditions, would you like to hear those?").replace("#CUSTOMERFULLNAME#", userName).replace("#DEPOSITAMOUNT#", "195")}
                    value={``}
                    handleClick={() => {setCheckBox(!checkBox) } }
                    onlyread={false}
                    checked={checkBox}
                    disabled={false}
                />
                <div className="mb-3">
                    <a href="javascript:;" style={{color:'#26B7EA',fontSize:17}} onClick={() => setShowDepositWaiverPopup(true)}>{t('Need a deposit alternative?')}</a>
                </div>
                <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>
                    If you qualify for any of these deposit waiver options, please email us the required information to <a href="mailto:waivedeposit@cleanskyenergy.com">waivedeposit@cleanskyenergy.com</a>.
                    Upon review and acceptance of your documentation, we will waive your deposit and release your enrollment for completion.
                    <br/>
                    <br/>
                    You also have the option of paying your deposit in two payments.  $95 will be due today, and the remaining $100 will be added to your first month’s invoice.  How would you like to proceed?                    <br/>
                    {!checkTransactionData(transactionData) && purpose === "deposit"  && <div>
                        <CheckboxItemNew
                                label={String("Customer does not wish to pay the deposit and/or has indicated that they will provide a Deposit Alternative.")}
                              onlyread={false}
                                   value={``}
                                handleClick={() => {setCheckBox2(!checkBox2); setCheckBox3(checkBox2)} }
                               checked={checkBox2}
                                disabled={false}
                        />
                    </div>}
                    {!checkTransactionData(transactionData) && purpose === "deposit"  && <div>
                        <CheckboxItemNew
                                label={String("Customer wishes to pay either the full or split deposit.")}
                              onlyread={false}
                                   value={``}
                                handleClick={() => {setCheckBox3(!checkBox3); setCheckBox2(checkBox3) } }
                               checked={checkBox3}
                                disabled={false}
                        />
                    </div>}
                </div>
            </div> :
            <div>
                {/* {!checkTransactionData(transactionData) && <div>
                    <CheckboxItemNew
                        label={String("Customer wishes to pay either the full or split deposit.")}
                        onlyread={false}
                            value={``}
                        handleClick={() => {setCheckBox3(!checkBox3); setCheckBox2(checkBox3) } }
                        checked={checkBox3}
                        disabled={false}
                    />
                </div>} */}
                <CheckboxItemNew
                    label={String("Agent Must Read Verbatim:  Mr./Ms. #CUSTOMERFULLNAME#, after review of your credit history, no deposit is required for you to establish service. For your security we do not accept credit card information over the phone. I will now send you a link to the email address you provided earlier, where you can enter your credit/debit card information. Please let me know when you are ready.").replace("#CUSTOMERFULLNAME#", userName)}
                    value={``}
                    handleClick={() => {setCheckBox(!checkBox) } }
                    onlyread={false}
                    checked={checkBox}
                    disabled={false}
                />
            </div>
        )
    }

    return (
        <>
            <Modal id="deposit-waiver-modal" size="lg" centered show={showDepositWaiverPopup} onHide={() => setShowDepositWaiverPopup(false)}>
                <Modal.Header closeButton>
                    <h4 className="pt-3">{t('DEPOSIT WAIVER CONDITIONS')}</h4>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>{t('By providing a Letter of Credit from a previous electric provider. DOCUMENTATION NEEDED: We will need a letter of credit from your previous supplier with 12 months of good payment history with no more than 1 late payment.')}</li>
                        <br/>
                        <li>{t('If you are 65 or older and you are currently not delinquent in payment of any electric service account. DOCUMENTATION NEEDED:  We will need a copy of your state issued driver’s license or identification card along with a copy of your most recent electric bill in your name that shows no past due balance.')}</li>
                        <br/>
                        <li>{t('If you are a Victim of Family Violence. DOCUMENTATION NEEDED:  A completed “Letter of Victim of Family Violence” waiver form, which we can provide to you if needed.')}</li>
                        <br/>
                        <li>{t('Military Option. DOCUMENTATION NEEDED:  A copy of your Veteran Industries ID Card if you’re currently active in the military or a copy of your Veteran Industries card and DD214 Form if you’re currently inactive in the military.')}</li>
                        <br/>
                    </ul>
                </Modal.Body>
            </Modal>
        <div className='container p-4 flex' style={{borderRadius: '11px', background: '#EFF7FA', width: '92%', height: isExistingCustomer ? 'auto' : '152px'}}>
            <div className="row">
                <div className="col-md-12">
                    <div className='d-flex align-items-center mb-2'>
                        {/* @ts-ignore */}
                        <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{purpose == "autopay"?"AutoPay Amount":`Pay Deposit: $${depositData?.deposit_suggested_amount}`}</div>
                    </div>
                    <div className='d-flex width-100'>
                        <div className='d-flex align-items-center mb-4' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>SNN:</div>
                            <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{ssn}</div>
                        </div>
                        <div className='d-flex align-items-center mb-4' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%', whiteSpace:'nowrap'}}>Date of Birth:</div>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{dob}</div>
                        </div>
                    </div>
                </div>

                {checkTransactionData(transactionData) && <div className="col-md-12">
                    <div className='d-flex align-items-center mb-2'>
                        <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{purpose == "autopay"?"Auto Pay Setup Status":"Deposit Amount Status"}</div>
                    </div>
                    <div className='d-flex justify-content-between width-100'>
                        <div className='d-flex align-items-center mb-2' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Credit Card:</div>
                            <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{`XXXX-XXXX-${transactionData?.cardNumber}`}</div>
                        </div>
                        <div className='d-flex align-items-center mb-2' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%', whiteSpace:'nowrap'}}>Expiry Date:</div>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{formatDate(transactionData?.cardExpDate)}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between width-100'>
                        {purpose !== "autopay" && <div className='d-flex align-items-center mb-2' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Deposit Amount Paid:</div>
                            <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{transactionData?.deposit === true ? `$${transactionData?.deposited_amount}` : "Not Paid"}</div>
                        </div>}
                        <div className='d-flex align-items-center mb-2' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%', whiteSpace:'nowrap'}}>Auto Pay Setup Complete:</div>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{transactionData?.autopay ? "Completed" : "Not Setup"}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between width-100'>
                        {purpose !== "autopay" && checkTransactionData(transactionData) && <div className='d-flex align-items-center mb-4' style={{width: "50%"}}>
                            <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Deposit Method:</div>
                            <div className='mr-2' style={{color: '#254066', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal', whiteSpace:'nowrap'}}>&nbsp;{transactionData?.split_deposit   == true ? "Split Deposit" : "Full Deposit"}</div>
                        </div>}
                    </div>
                </div>}

                <div className="col-md-12">
                    {CheckBoxVerbiage()}
                </div>
                {!checkTransactionData(transactionData) && (checkBox3) && <>
                    {purpose==="deposit" && <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>
                        If you pay your deposit today, the same credit card used to pay the refundable deposit will be used to establish your Auto Pay.
                    </div>}
                    <br/>
                    <br/>
                    {showLinkSendSection?<div className='col-md-12'>
                        <div className='mr-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Send Magic Link for {purpose==="deposit" ? "Payment" : "Autopay Setup"}:</div>
                        <div className='mt-2 d-flex'>
                                <input onChange={(e)=>setUserEmail(e?.target?.value)} className='mr-4' type='text' style={{borderRadius: '10px', border: '1px solid #CED4DA', width: '100%', height:'38px', marginRight: "10px"}} value={userEmail} />
                                {sentLoader? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/>
                                :<a onClick={handleLinkSubmit}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                        <circle cx="16.5" cy="16.5" r="16.5" fill={checkBox ? "#617692" : "grey"}/>
                                        <path d="M7.47839 16.571C6.81763 16.9176 6.84504 17.4253 7.53778 17.6995L9.59032 18.5116C10.2836 18.7868 11.3327 18.6366 11.9204 18.178L20.8336 11.1489C21.4195 10.6881 21.4823 10.756 20.9735 11.3008L13.9273 18.8428C13.4167 19.3859 13.5675 20.053 14.2631 20.3219L14.5035 20.4156C15.1991 20.6846 16.3327 21.1363 17.0238 21.4173L19.3002 22.3442C19.9918 22.6252 20.7308 22.294 20.9272 21.5744L24.3967 8.8217C24.5925 8.10212 24.2122 7.79658 23.5514 8.14267L7.47839 16.571Z" fill="white"/>
                                        <path d="M13.1111 24.9198C13.0705 25.0415 14.5148 22.8285 14.5148 22.8285C14.9232 22.2048 14.6913 21.4607 14.0014 21.1786L12.4263 20.5338C11.7364 20.2517 11.4058 20.5829 11.6913 21.2722C11.6913 21.2722 13.1528 24.7948 13.1111 24.9198Z" fill="white"/>
                                    </svg>
                                </a>}
                        </div>
                    </div>:
                    <div className='col-md-12'>
                        <div style={{display:"flex",justifyContent:"center"}}>
                        <Button
                            type='button'
                            disabled={loader}
                            onClick={async () => {
                                await sendPaymentScreen()
                            }}>
                            {t('Send Payment Information')}
                        </Button>
                </div>
                    </div>
                    }
                    {sent && <div style={{marginTop: "10px", height: "fit-content", display:"flex",alignItems:"center"}}>
                        <img src="/images/checkFill.svg" height="24px" width="24px" /> &nbsp;
                        <div style={{color:"#254066",fontSize:"12px"}}>The Magic Link has been sent</div>
                    </div>}
                    <br/>
                    <br/>
                    <br/>
                    <div className='col-md-12' style={{display: "flex", alignItems: "center"}}>
                        Refresh Payment Status {fetchLoader ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <img src="/images/refreshIcon.svg" height="24px" width="24px" style={{cursor: 'pointer', marginLeft: "10px"}} onClick={()=>fetchData()}/>}
                    </div>
                </>}
            </div>
        </div>
        {<div style={{width:"92%", margin:"auto"}}>
            {
                checkBox2 && !checkTransactionData(transactionData) && 
                <div style={{display:"flex",justifyContent:"right"}}>
                    <Button
                        withArrow
                        type='button'
                        onClick={() => {
                            window.location.href = adminPortalAgent
                        }}>
                        {t('Back to Enrollment Portal')}
                    </Button>
                </div>
            }
        </div>}
        <br />
        {checkTransactionData(transactionData) &&
        <div style={{margin:"0rem 1.5rem", padding: "0rem 1rem"}}>
            <div>{purpose === "autopay"?
            "Customer has setup their Auto Pay – Press continue to review and verify the customer information and complete the enrollment.":
            "Customer has paid their deposit and setup Auto Pay – Press continue to review and verify the customer information and complete the enrollment."}</div>
            <div style={{display:"flex",justifyContent:"flex-end",margin:"auto"}}>
                <Button type="button"   onClick={() => handleSubmit()}>
                    {t('Continue')}
                </Button>
            </div>
        </div>
        }
        </>
    )
}

