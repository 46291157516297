import { postToHubSpot } from "../api/api";
import { getEnrollmentRecord } from "../api/enrollment";

export const hubspotApiCalls = async(enrollmentId, sign, abandoned_status, magic_link, pageName) =>{
    let res = await getEnrollmentRecord(enrollmentId, sign)
    let customerData = res?.response;
    let transactionalData = JSON.parse(customerData?.ext_data)
    
    //This is for updating contact info in HUBSPOT
    await postToHubSpot(
        customerData?.enrollment_id,
        transactionalData?.ext_sms_marketing_opt_in,
        transactionalData?.ext_sms_transactional_opt_in,
        transactionalData?.ext_paperless,
        customerData?.email,
        customerData?.phone,
        customerData?.first_name,
        customerData?.last_name,
        customerData?.promo_code,
        customerData?.service_state,
        abandoned_status,
        magic_link,
        pageName
    );
}

export default hubspotApiCalls;