import { getIsAgentValid } from "../api/api";

const isAdmin = async () =>{
    try{
        const url = new URL(window.location.href);
        const agentId = url.searchParams.get('agent_id');
        if(agentId){
            const resp = await getIsAgentValid({id:agentId})
            const res =  resp[0].status
            return res
        }else{
            return false
        }
    }catch(err){
        return false
    }
}

export default isAdmin;