import React from 'react';

export const InvalidRegion = () =>{
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <a href='https://cleanskyenergy.com/'><img src='/images/logo.svg' height={'200px'} width={'300px'}/></a>
            <h1>You have been blocked</h1>
            <h2>You are unable to access this website</h2>
            <p><a href="https://cleanskyenergy.com/">cleanskyenergy.com</a> does not serve your country/region.</p>
        </div>
    )
}

export default InvalidRegion;