import React, { useState, useEffect, useContext, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import type { Address } from './types';
import { fetchAddress, getConfigById } from './api/api';
import { EnrollmentContext } from './enrollment';
import { useTranslation } from 'react-i18next';
import { CustomAddressModal } from './components/UtilityWarningModal';
import {  Dropdown, Form, Spinner } from 'react-bootstrap'
import { Button } from './components/styles/Button.styled';
import { FormError } from './components/enrollment/FormError';
import { EnrollmentFormCol, EnrollmentFormRow, EnrollmentStepFooter } from './components/styles/Enrollment.styled';
import NumberFormat from 'react-number-format';

export const ValidateAddress = ({ handleSearchUtility, loadingUtility, manualAddressFields, setManualAddressFields }: {
    handleSearchUtility: Function;
    loadingUtility: boolean;
    manualAddressFields: boolean;
    setManualAddressFields: Function
}) => {
    const { t } = useTranslation('enrollment');
    const [ searching, setSearching ] = useState(false);
    const [ addressOptions, setAddressOptions ] = useState<Address[]>([]);
    const [ selectedAddress, setSelectedAddress ] = useState<Address[]>([]);
    const [ RESIDENTIAL_CUSTOMERS_ONLY, SET_RESIDENTIAL_CUSTOMERS_ONLY ] = useState<boolean>(false);
    const { changeServiceAddress, loading, serviceAddress, handleSetErrorModalContent } = useContext<any>(EnrollmentContext);
    const inputRef = useRef<any>();
    const [showCustomAddress,setShowCustomAddress] = useState(false)
    const [ esiid, setEsiid ] = useState("")
    const [address, setAddress] = useState('');
    const [unitNo, setUnitNo] = useState('');
    const [city, setCity] = useState('');
    const [selectedState, setSelectedState] = useState('TX');
    const [errorFields, setErrorFields] = useState<any>({});


    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}
        if (!address) {
            newErrorFields['service_address'] = t('This field is required')
            isValid = false
        }
        if (!city) {
            newErrorFields['service_city'] = t('This field is required')
            isValid = false
        }
        if (!selectedState) {
            newErrorFields['service_state'] = t('This field is required')
            isValid = false

        }
        // if (!unitNo) {
        //     newErrorFields['service_unit'] = t('This field is required')
        //     isValid = false

        // }
        if (!esiid) {
            newErrorFields['esiid'] = t('This field is required')
            isValid = false

        }

        setErrorFields(newErrorFields)
        return isValid
    }

    useEffect(() => {
        if (selectedAddress.length === 0 || selectedAddress[0]?.premise_type !== "Residential") return;
        handleSearchUtility(selectedAddress[0]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    useEffect(()=>{
        (async()=>{
            let res = await getConfigById("RESIDENTIAL_CUSTOMERS_ONLY");
            const flag = Boolean(Number(res?.config_details?.ConfigData))
            SET_RESIDENTIAL_CUSTOMERS_ONLY(flag)
        })()
    }, [])

    const handleSearch = (val:string) => {
        setSearching(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setSearching(false);
            if(data?.length==0){
                setShowCustomAddress(true)
            }
        })
    }

    const handleSave=()=>{
        if (!validate()) return;
        try{
            changeServiceAddress({...serviceAddress,
                "street": address,
                "value": address,
                "unit": unitNo,
                "city": city,
                "state": "TX",
                "esiid": esiid,
                "status": "Active",
                "zipcode":localStorage.getItem('zipcode')
    
            })
        }catch(err){

        }
    }

    return (
        <>
            {!manualAddressFields ?
            // {true ?
            <div className="autocomplete-address-row">
                <div id="autocomplete-wrapper">
                    <AsyncTypeahead
                        ref={inputRef}
                        filterBy={() => true}
                        id="autocomplete"
                        isLoading={searching || loadingUtility}
                        labelKey="label"
                        minLength={1}
                        onSearch={handleSearch}
                        options={addressOptions}
                        placeholder={`${t('Address')}...`}
                        size="lg"
                        onChange={(val) => {
                            setSelectedAddress(val);
                            
                            if(val.length > 0 && val[0]?.premise_type !== "Residential" && RESIDENTIAL_CUSTOMERS_ONLY){
                                changeServiceAddress(undefined);
                                handleSetErrorModalContent({
                                    title: t("We're sorry, CleanSky Energy currently accepts only residential customers."),
                                    desc: ' '
                                });
                            }else{
                                changeServiceAddress(val[0] ? val[0] : undefined);
                            }

                            if (val.length > 0) {
                                inputRef.current.blur();
                            }
                        }}
                        selected={selectedAddress.length > 0 ? selectedAddress : (serviceAddress ? [serviceAddress] : [])}
                        disabled={loading}
                    />
                </div>
            </div>:
                    <div className="mt-2">
                    <EnrollmentFormRow>
                        <EnrollmentFormCol full>
                            <Form.Group className={errorFields['service_address'] ? 'error' : ''}>
                                <Form.Label>{t('Address')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_address"
                                    placeholder=""
                                    maxLength={200}
                                    value={address}
                                    onChange={evt => setAddress(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_address']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_unit'] ? 'error' : ''}>
                                <Form.Label>{t('Unit/Suite/Apt Number')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_unit"
                                    placeholder=""
                                    maxLength={50}
                                    value={unitNo}
                                    onChange={evt => setUnitNo(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            {/* <FormError message={errorFields['service_unit']} /> */}
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_city'] ? 'error' : ''}>
                                <Form.Label>{t('City')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_city"
                                    placeholder=""
                                    maxLength={50}
                                    value={city}
                                    onChange={evt => setCity(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_city']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_state'] ? 'error' : ''}>
                                <Form.Label>{t('State')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_state"
                                    placeholder=""
                                    maxLength={50}
                                    value={selectedState}
                                    // onChange={evt => setSelectedState(evt.target.value)}
                                    disabled={true}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_state']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['esiid'] ? 'error' : ''}>
                                <Form.Label>{t('Esiid')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="esiid"
                                    placeholder=""
                                    maxLength={50}
                                    value={esiid}
                                    onChange={evt => setEsiid(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['esiid']} />
                        </EnrollmentFormCol>
                    </EnrollmentFormRow>
                    <EnrollmentStepFooter>
                        <div />
                        <Button type="button" disabled={loading} onClick={() => handleSave()} data-test-id="cta">
                            <span>{t('Continue')}</span>
                            {loading && (
                                <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                            )}
                        </Button>
                    </EnrollmentStepFooter>
                </div>
            }
            {
                selectedAddress[0] &&
                <p style={{fontSize:12,marginTop:8,marginLeft:4,color:'#666'}}>{`ESIID: ${selectedAddress[0]?.esiid}`}</p>
            }
            <CustomAddressModal show={showCustomAddress} setShow={setShowCustomAddress} handleYes={()=>{setManualAddressFields(true); setShowCustomAddress(false)}}/>
        </>
    )
}
