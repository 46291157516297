import React, { useContext, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    EnrollmentFormRow,
    EnrollmentFormCol,
    CreditCheckSection,
    InputWithMask,
    InputMaskToggle,
    CheckboxDesc
} from '../styles/Enrollment.styled'
import { Button } from '../styles/Button.styled'
import { Form, Dropdown, Spinner, InputGroup, DropdownButton } from 'react-bootstrap'
import { CheckboxItem, CheckboxItemNew } from '../checkbox-item'
import { statesList } from '../../states-list'
import { FormError } from './FormError'
import { confirmEnrollment, getEnrollmentRecord, saveEnrollment } from '../../api/enrollment'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { LoadingRow } from '../loading-row'
import { EnrollmentContext } from '../../enrollment'
import { getAgentScript, postToHubSpot, createDealHubspot, getSwitchVelocityDetails, getSwitchHoldDetails, sendCustomMagicLink, getCreditCheckData, getConfigById } from '../../api/api'
import { useLocation } from 'react-router'
import qs from 'qs'
import isAdmin from '../../utils/isAdmin'
import { ENROLLMENT_PORTAL_URL, adminPortalAgent } from '../../config'
import ExistingCustomer from './AgentWebPortal/ExistingCustomer'
import { dealStages } from '../../config'
import getStatusByCode from '../../utils/getStatusByCode'
import hubspotApiCalls from '../../utils/hubspotApiCalls'
import { CircularProgress } from '@mui/material'
import { message } from 'antd'

interface Props {
    show: boolean
    handleBack: Function
    handleContinue: Function
    dynamicFields: any
    specialPro: {
        ssn: any
        date_of_birth: any
    }
    setSpecialPro?: any;
    stateId?: string;
    handleSetCustomerName: Function;
    setScriptData:any;
    openReplayTracker: any;
}

interface CreditCheckTypeOption {
    label: string
    value: string
    desc: string
}
interface StatesOption {
    name: string
    abbr: string
}

const creditCheckOptions = [
    {
        id: 'credit_check_type_ssn',
        label: 'SSN',
        value: 'ssn',
        desc: '',
    },
    {
        id: 'credit_check_type_dob',
        label: 'Date of Birth',
        value: 'dob',
        desc: '',
    }
]

export const EnrollmentStepInfo: React.FC<Props> = ({
    show,
    handleBack,
    handleContinue,
    dynamicFields,
    specialPro,
    setSpecialPro,
    stateId,
    handleSetCustomerName,
    openReplayTracker,
    setScriptData,
}) => {
    const { t, i18n } = useTranslation('enrollment')

    const phoneTypeOptions = [
        { label: 'Mobile', value: 'Mobile' },
        { label: 'Land Line', value: 'Land_Line' },
    ]

    const languageOptions = [
        { label: 'English', value: 'english' },
        { label: 'Spanish', value: 'spanish' }
    ]
    const location = useLocation()
    const { promocode } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        })
    const [inputValue, setInputValue] = useState<any>({
        first_name: { type: 'text', value: '' },
        last_name: { type: 'text', value: '' },
        email: { type: 'text', value: '' },
        email2: { type: 'text', value: '' },
        phone: { type: 'number', value: '' },
        phone2: { type: 'number', value: '' },
        billing_address: { type: 'text', value: '' },
        billing_address2: { type: 'text', value: '' },
        billing_city: { type: 'text', value: '' },
        billing_state: { type: 'select', value: '' },
        billing_zipcode: { type: 'text', value: '' },
        billing_county: { type: 'text', value: '' },
        promo_code: { type: 'text', value: '' },
        language: { type: 'select', value: { label: 'English', value: 'english' } },
        method_delivery: { type: 'text', value: 'email' },
        authorized_first_name: { type: 'text', value: '' },
        authorized_last_name: { type: 'text', value: '' }
    })
    const [isSameBillingAddress, setIsSameBillingAddress] = useState(true)
    const [billingState, setBillingState] = useState<StatesOption | undefined>(undefined)
    const [isAgree, setIsAgree] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ magicLinkSentCreditCheck, setMagicLinkSentCreditCheck ] = useState(false)
    const [errorFields, setErrorFields] = useState<any>({})
    const [requiredFields, setRequiredFields] = useState<any>({
        last_name: { type: 'text' },
        first_name: { type: 'text' },
        email: { type: 'email' },
        email2: { type: 'email' },
        phone: { type: 'number' },
    })
    const [ showSSN, setShowSSN ] = useState(false);
    const [ phoneType, setPhoneType ] = useState(phoneTypeOptions[0]);
    const [ creditCheckType, setCreditCheckType ] = useState<CreditCheckTypeOption>(creditCheckOptions[0]);
    const [ enableAuthorizedRepresentative, setEnableAuthorizedRepresentative ] = useState(false);
    const [ readOnlyPromoCode, setReadOnlyPromoCode ] = useState(false);
    const { serviceAddress, storePromoCode, handleSetErrorModalContent, isTx, enrollmentTokens, selectedPlans } = useContext<any>(EnrollmentContext);
    const promoCodeRef = useRef<null | HTMLDivElement>(null);
    const emailInputRef = useRef<null | HTMLDivElement>(null);
    const [isAgent,setAgent] = useState(false);
    const { utm_content } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        })
     const [ userEmailForMagicLink , setUserEmailForMagicLink ] = useState("");
    const [ fetchSsnDobLoader , setFetchSsnDobLoader ] = useState(false);
    const [ creditCheckData, setCreditCheckData ] = useState<{ssn: string|null, dob: string|null}>({
        ssn: null,
        dob: null
    })
    const [ checkedExistingCustomer, setCheckedExistingCustomer ] = useState<boolean>(false)
    const [ samePremise, setSamePremise ] = useState<boolean>(false)
    const [linkSent,setLinkSent] = useState(false)
    const [ SHOW_LINK_FOR_CREDIT_CHECK, SET_SHOW_LINK_FOR_CREDIT_CHECK ] = useState<boolean>(false)
    const [agentBillDelivery,setAgentBillDelivery] = useState(false)
    const [ccConsent,setCcConsent] = useState(false) 
    const [defaultEmail,setDefaultEmail] = useState(false)


    const submit = (enrollmentId: string, sign: string, productId: string, customerId: string) => {
        return new Promise((resolve, rejects) => {
            setSpecialPro(specialPro)
            confirmEnrollment(
                enrollmentId,
                specialPro,
                sign,
                utm_content ? utm_content : '',
                '',
                '',
                customerId,
                null
            ).then(async(data) => {
                if (data) {
                    if (data.error_code && data.error_code === '500') {
                        handleSetErrorModalContent({
                            title: t(data.message) || t('The enrollment request has been submitted')
                        });
                        // setSubmitting(false);
                        return;
                        // rejects(data.message || t('The enrollment request has been submitted'));
                    }
                    const { status } = data
                    const abandoned_status = getStatusByCode(status)
                    await hubspotApiCalls(enrollmentId, sign, abandoned_status, "", "Information")

                    if (status) {
                        const errorCode = status.split('.')[0];

                        switch (errorCode) {
                            case '0':
                            case '-1':
                                if (data.message && (data.message.toLowerCase().indexOf('email_repeated') >= 0 || data.message.toLowerCase().indexOf('email_enrolled') >= 0)) {
                                    rejects('DuplicateAccount');
                                }
                                else {
                                    rejects(t(data.message));
                                }
                                if (data.message && data.message.indexOf('promoCode') >= 0) {
                                    rejects('InvalidPromoCode');
                                }
                                break
                            case '30':
                                if (data && data.additional && data.additional[0] && data.additional[0].account_number === null) {
                                    const depositAmount = data.additional[0].deposit_amount ? data.additional[0].deposit_amount : 0;
                                    rejects(`PayDeposit:${depositAmount}`);
                                }
                                else {
                                    resolve(Object.assign({}, data, { productId }));
                                }
                                break;
                            case '10':
                            case '20':
                            case '40':
                            case '50':
                                resolve(data);
                                break
                            default:
                        }
                    }
                    else {
                        rejects(t('An error occurred'));
                    }
                }
                else {
                    rejects(t('An error occurred'));
                }
            })
            .catch(error => rejects(error))
        })
    }

    const getScriptForAgent = async()=>{
         try{
            const res = await getAgentScript({
                script_id:"2",
                language:i18n.language === 'es' ? 'spanish' : 'english',
                state: stateId || "TX",
                enrollment_id: enrollmentTokens[0]?.enrollment_id,
                commodity: (selectedPlans.length > 1 && stateId == "OH") ? "gas" : selectedPlans[0]?.commodity_name,
                utility_code: selectedPlans[0]?.id_utility,
            })
            setScriptData({
                //@ts-ignore
                script: res.length>0 ? res[0]?.script_content:"",
                scriptHead: res.length>0 ? res[0]?.screen_name:"",
                dynamicData:{}
            })
         }catch(e){
             console.log(e)
         }
     }

    useEffect(() => {
        if (show) {
            setCheckedExistingCustomer(false)
            window.scrollTo(0, 0);
            (async()=>{
                let _isAdmin = await isAdmin();
                if(_isAdmin){
                    getScriptForAgent();
                    setAgent(true)
                }else{
                    setCheckedExistingCustomer(true);
                    setSamePremise(false)
                }
            })();
        }
    }, [show])

    useEffect(()=>{
        if(show){
            (async()=>{
                try{
                    await getSwitchHoldDetails(serviceAddress?.esiid)
                    await getSwitchVelocityDetails(serviceAddress?.esiid)
                }catch(err){
                }
            })()
        }
    },[show])

    useEffect(() => {
        if (promocode) {
            const newInputValue = Object.assign({}, inputValue);
            newInputValue.promo_code.value = promocode;
            setInputValue(newInputValue);
            setReadOnlyPromoCode(true);
        }
    }, [promocode])

    useEffect(() => {
        setTimeout(() => {
            const promoCodeError = localStorage.getItem('promoCodeError');
            if (promoCodeError) {
                setErrorFields({
                    'promoCode': 'Invalid Promo Code'
                })
                localStorage.removeItem('promoCodeError');
            }
        }, 500)
    }, [show])

    useEffect(() => {
        if (dynamicFields) {
            let newInputValue = Object.assign({}, inputValue),
                newRequiredFields: any = Object.assign({}, requiredFields)

            for (let cate in dynamicFields) {
                for (let key in dynamicFields[cate]) {
                    if (key === 'primary_phone_type') continue;
                    const item = dynamicFields[cate][key]
                    const { type } = item
                    if (key === 'ssn') {
                        newInputValue[key] = { type: 'number', value: '' }
                    } else {
                        if (type === 'checkbox') {
                            let checkboxVal = false;
                            if (key === 'ext_sms_marketing_opt_in') {
                                checkboxVal = true;
                            }
                            newInputValue[key] = { type: type, value: checkboxVal }
                        } else if (type === 'date' || type === 'select') {
                            if (key === 'prefered_contact' || key === 'ext_delivery_method') {
                                // default select one option
                                if(key === 'ext_delivery_method' && isAgent && isTx){
                                    newInputValue[key] = { type: type, value: undefined }
                                }else{
                                    newInputValue[key] = { type: type, value: item.value[0] }
                                }
                            }
                            else {
                                newInputValue[key] = { type: type, value: undefined }
                            }
                        } else {
                            newInputValue[key] = { type: type, value: '' }
                        }
                    }
                    if (key !== 'premise_type' && !item.optional) {
                        newRequiredFields[key] = { type: type }
                    }
                }
            }

            if (i18n.language === 'es') {
                newInputValue.language.value = { label: 'Spanish', value: 'spanish' };
                setInputValue(newInputValue);
            }

            setInputValue(newInputValue)
            setRequiredFields(newRequiredFields)
        }
        else {
            if (show) {
                handleBack();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, dynamicFields])

    useEffect(()=>{
        if(show){
            (async()=>{
                let res = await getConfigById("SHOW_LINK_FOR_CREDIT_CHECK");
                const flag = Boolean(Number(res?.config_details?.ConfigData))
                SET_SHOW_LINK_FOR_CREDIT_CHECK(flag)
                try{
                    await getSwitchHoldDetails(serviceAddress?.esiid)
                    await getSwitchVelocityDetails(serviceAddress?.esiid)
                }catch(err){
                    console.log('Data in err ==>', err)
                }
            })()
        }
    },[show])

    function formatSSN(ssn: any) {
        try{
            return `XXX-XX-${ssn.slice(-4)}`;
        }catch(err){
            return ssn;
        }
    }
    
    const sendCreditCheckFieldsLink = async() =>{
        if(!userEmailForMagicLink) return;
        try{
            setMagicLinkSentCreditCheck(true);
            let res = await sendCustomMagicLink(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, userEmailForMagicLink,i18n.language === 'es' ? 'spanish' : 'english', "credit_check", "creditCheck");
            if(JSON.parse(res.response)?.sendResult === "SENT"){
                message.success({
                    content: 'Magic Link Mail sent successfully to fill Credit Check Fields',
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
                setLinkSent(true);
            }
            else{
                message.error({
                    content: JSON.parse(res.response)?.message,
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
            }
            setMagicLinkSentCreditCheck(false);
        }catch(err){
            setMagicLinkSentCreditCheck(false);
            message.error({
                content: 'Failed to send Mail for Credit Check Fields',
                style: {
                    position: 'absolute',
                    width: "100%",
                    top: "30px"
                },
            });
        }
    }

    const fetchSsnDobData = async() =>{
        try{
            setFetchSsnDobLoader(true)
            // API call to fetch SSN DOB
            const res = await getCreditCheckData(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign)
            const data = res?.response;
            if(moment(data?.date_of_birth).isValid() && data?.ssn?.length === 9){
                setInputValue({...inputValue, ssn: {
                    "type": "number",
                    "value": data?.ssn
                }, date_of_birth: {
                    "type": "date",
                    "value": moment(data?.date_of_birth).format("MM/DD/YYYY")
                }})
                setCreditCheckData({
                    ssn: data?.ssn,
                    dob: data?.date_of_birth
                })
            }
            setFetchSsnDobLoader(false)
        }catch(err){
            setFetchSsnDobLoader(false)
        }
    }

    function validateEmail(email:any) {
        // List of basic email domains for spell check
        var basicDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com','testing.com','eiqdigital.com','cleanskyenergy.com','yopmail.com','msn.com','mail.com'];
     
        // Regular expression pattern for email validation
        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
     
        // Extracting domain from email
        var domain = email.split('@').pop().toLowerCase();
     
        // Checking if domain is in basicDomains
        if (!basicDomains.includes(domain)) {
            // throw new Error("Invalid email domain. Please use one of the following: " + basicDomains.join(', '));
            return false
        }
     
        // Validating email format
        if (!pattern.test(email)) {
            // throw new Error("Invalid email format");
            return false
        }
     
        return true;
    }

    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}
        
        for (let i in requiredFields) {
            const { type } = requiredFields[i]
            if (type === 'checkbox') {
                continue
            } else if (type === 'date') {
                if (
                    // (i === 'date_of_birth' && creditCheckType.value !== 'dob') ||
                    isTx === false) continue;
                if (
                    !inputValue[i].value ||
                    !(
                        moment(inputValue[i].value, 'MM/DD/YYYY', true).isValid()
                    )
                ) {
                    newErrorFields[i] = t('Invalid Date')
                    isValid = false
                }
                if (i === 'date_of_birth') {
                    const age = moment().diff(moment(inputValue[i].value, 'MM/DD/YYYY'), 'years');
                    
                    if (age < 18) {
                        newErrorFields[i] = t('Date of Birth is under 18 years')
                        isValid = false
                    }
                    if(age > 105){
                        newErrorFields[i] = t('Please enter valid Date of Birth')
                        isValid = false
                    }
                }
            } else if (type === 'email') {
                // eslint-disable-next-line
                // const pattern = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}(?<!\.om)$/gm;
                // if (!pattern.test(inputValue[i].value)) {
                //     newErrorFields[i] = t('Invalid emaill address format')
                //     isValid = false
                // }
                if(!validateEmail(inputValue[i].value)){
                    newErrorFields[i] = t('Invalid emaill address format')
                    isValid = false
                }
            } else {
                if (!inputValue[i].value) {
                    if (
                        // (i === 'ssn' && creditCheckType.value !== 'ssn') ||
                    (i === 'ssn' && isTx === false)) continue;
                    newErrorFields[i] = t('This field is required')
                    isValid = false
                }
            }
        }

        if (inputValue.phone.value.indexOf('_') >= 0) {
            newErrorFields['phone'] = t('Invalid Phone Number')
            isValid = false
        }

        if (creditCheckType.value === 'ssn' && inputValue.ssn.value.indexOf('_') >= 0 && isTx) {
            newErrorFields['ssn'] = t('Invalid SSN format')
            isValid = false
        }

        if (inputValue.email.value && inputValue.email2.value) {
            if (inputValue.email.value !== inputValue.email2.value) {
                newErrorFields['email'] = t('Email address does not match')
                newErrorFields['email2'] = t('Email address does not match')
                isValid = false
            }
        }

        if (!isSameBillingAddress && !billingState) {
            newErrorFields['billingState'] = t('This field is required')
            isValid = false
        }

        if (!isAgree) {
            newErrorFields['isAgree'] = t('This field is required')
            isValid = false
        }
        
        setErrorFields(newErrorFields)
        return isValid
    }

    const validatePre = () => {
        let isValid = true
        let newErrorFields: any = {}
        
        for (let i in requiredFields) {
            const { type } = requiredFields[i]
            if (type === 'checkbox') {
                continue
            } else if (type === 'email') {
                // eslint-disable-next-line
                // const pattern = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}(?<!\.om)$/gm;
                // if (!pattern.test(inputValue[i].value)) {
                //     newErrorFields[i] = t('Invalid emaill address format')
                //     isValid = false
                // }
                if(!validateEmail(inputValue[i].value)){
                    newErrorFields[i] = t('Invalid emaill address format')
                    isValid = false
                }
            } else {
                if(i === "ext_delivery_method" && isAgent){

                }
                else if(!inputValue[i].value) {
                    newErrorFields[i] = t('This field is required')
                    isValid = false
                }
            }
        }

        if (inputValue.phone.value.indexOf('_') >= 0) {
            newErrorFields['phone'] = t('Invalid Phone Number')
            isValid = false
        }

        if (inputValue.email.value && inputValue.email2.value) {
            if (inputValue.email.value !== inputValue.email2.value) {
                newErrorFields['email'] = t('Email address does not match')
                newErrorFields['email2'] = t('Email address does not match')
                isValid = false
            }
        }

        delete newErrorFields['ssn'];
        delete newErrorFields['date_of_birth'];
        setErrorFields(newErrorFields)
        return Object.values(newErrorFields).length > 0 ? false : true
    }

    const handleSave = async () => {
        if (loading || !validate()) return;

        setLoading(true)

        let saveFields: any = {};
        let trueFields: any = {};

        const serviceAddr: any = {
            address: serviceAddress.street,
            state: serviceAddress.state,
            city: serviceAddress.city,
            zipcode: serviceAddress.zipcode,
            county: serviceAddress.county || '',
            address2: serviceAddress.unitNo || ''
        }

        await openReplayTracker?.setUserID(inputValue?.email?.value);

        for (let i in inputValue) {
            if (i === 'email2') continue

            const item = inputValue[i]
            if (i.indexOf('billing_') >= 0) {
                const _key = i.substr(8)
                if (i === 'billing_address') {
                    const billingAddress = `${item.value}`
                    saveFields[i] = isSameBillingAddress
                        ? serviceAddr[_key]
                        : billingAddress.trim()
                } else {
                    saveFields[i] = isSameBillingAddress
                        ? serviceAddr[_key]
                        : inputValue[`billing_${_key}`].value
                }
            } else {
                if (item.type === 'number') {
                    saveFields[i] = item.value ? item.value.replace(/ /g, '') : ''
                } else if (item.type === 'date') {
                    saveFields[i] = moment(item.value).format('MM/DD/YYYY')
                } else if (item.type === 'select') {
                    saveFields[i] = item.value ? item.value.value : ''
                } else {
                    saveFields[i] = item.value
                    if(defaultEmail){
                        saveFields.email = "tgpwebcustomer@titangasandpower.com"
                        // saveFields.email2 = "tgpwebcustomer@titangasandpower.com"
                    }
                }
            }
        }

        saveFields.primary_phone_type = phoneType.value;
        saveFields.autopay_required = Boolean(selectedPlans[0]?.autopay_required);

        for (const key in saveFields) {
            if (key === 'ssn') {
                // specialPro['ssn'] = creditCheckType.value === 'ssn' ? saveFields[key] : '';
                specialPro['ssn'] = saveFields[key];
            } else if (key === 'date_of_birth') {
                // specialPro['date_of_birth'] = creditCheckType.value === 'dob' ? saveFields[key] : '';
                specialPro['date_of_birth'] = saveFields[key];
            } else if(key === "first_name" || key === "last_name"){
                trueFields[key] = saveFields[key] ? saveFields[key]?.trim() : ""
            } else {
                trueFields[key] = saveFields[key]
            }
        }
        // console.log(saveFields)
        // console.log(specialPro)
        // trueFields['premise_type'] = 'House'; // hard code
        storePromoCode(inputValue.promo_code.value);

        let promises: Promise<any>[] = [];
        enrollmentTokens.forEach((item: any) => {
            const findPlan = selectedPlans.find((plan: any) => plan.plan_id === item.productId);
            if (findPlan) {
                const { enrollment_id, sign } = item;
                trueFields['product'] = findPlan.product;
                window.dataLayer.push({
                    'productName': selectedPlans[0].plan_name,
                    'term': selectedPlans[0].contract_term,
                    'rate': isTx ? selectedPlans[0].price2000 : selectedPlans[0].rate,
                    'customerName': `${trueFields.first_name} ${trueFields.last_name}`,
                    'email': trueFields.email,
                    'date': moment().format('MM/DD/YYYY')
                });

                promises.push(saveData(trueFields, enrollment_id, sign));
            }
        })

        Promise.all(promises).then(async (res: any) => {
            if (res) {
                if (res[0]) {
                    const { email, phone, ext_data } = res[0];
                    if (ext_data) {
                        const ext_data_obj = JSON.parse(ext_data);
                        const { ext_paperless, ext_sms_marketing_opt_in, ext_sms_transactional_opt_in } = ext_data_obj;
                        await postToHubSpot(
                            enrollmentTokens[0]?.enrollment_id,
                            ext_sms_marketing_opt_in,
                            ext_sms_transactional_opt_in,
                            ext_paperless,
                            email,
                            phone,
                            trueFields.first_name,
                            trueFields.last_name,
                            trueFields.promo_code,
                            serviceAddress.state,
                            "",
                            "",
                            "Information"
                        );
                        //API call to create a Deal in Hubspot - initial phase - first retrieve data from api
                        const dateToCreateDeals = await getEnrollmentRecord(enrollmentTokens[0].enrollment_id, enrollmentTokens[0].sign);
                        await createDealHubspot(enrollmentTokens[0].enrollment_id, trueFields.promo_code || null, dateToCreateDeals.response, selectedPlans[0], dealStages.ENROLLMENT_INITIATED)
                       
                        handleSetCustomerName(`${trueFields.first_name} ${trueFields.last_name}`);
                        let _isAdmin = await isAdmin();

                        if(_isAdmin && isTx){
                            let customerId = '';
                            let promises: Promise<any>[] = [];

                            for (let item of enrollmentTokens) {
                                const { enrollment_id, sign, productId } = item;
                                const res: any = await submit(enrollment_id, sign, productId, customerId);
                                if (res && res.new_additional && res.new_additional.customer_id) {
                                    customerId = res.new_additional.customer_id
                                    console.log(customerId)
                                }
                                promises.push(res);
                            }

                            Promise.all(promises).then((res: any) => {
                                setLoading(false);
                    
                                if (res) {
                                    handleContinue(res);
                                }
                            }).catch(error => {
                                setLoading(false);
                                if (error === 'DuplicateAccount') {
                                    handleSetErrorModalContent({
                                        title: t('Are you a current customer?'),
                                        desc: t('It looks like you already have an account with us, please login to continue.'),
                                        button: 'login'
                                    });
                                }
                                if (error === 'InvalidPromoCode') {
                                    handleBack()
                                    localStorage.setItem('promoCodeError', '1');
                                }
                                if (error.indexOf('PayDeposit') >= 0) {
                                    const depositAmount = error.split('PayDeposit:')[1];
                                    handleSetErrorModalContent({
                                        title: t("A deposit is required to continue with this enrollment. Deposit amount: ##AMOUNT##. Please give us a call at ##PHONENUMBER##. Our representatives are available to assist you Monday – Friday from 8:00am – 7:00pm and Saturday 9am – 1pm CST.").replace(/##AMOUNT##/, depositAmount).replace(/##PHONENUMBER##/, isTx ? '(888) 733-5557' : '(888) 355-6205'),
                                        desc: ' ',
                                        buttonText: t('Cancel')
                                    });
                                }
                                handleSetErrorModalContent({
                                    title: t(error)
                                });
                            })
                        }

                    }
                }
                setLoading(false);
                handleContinue(specialPro);
            }
            else {
                setLoading(false);
                handleSetErrorModalContent({
                    title: t('Please try again')
                });
            }
        }).catch(error => {
            setLoading(false);
            handleSetErrorModalContent({
                title: t(error)
            });
        })
    }

    const handleSavePre = async () => {

        if (loading || !validatePre()) return;

        let saveFields: any = {};
        let trueFields: any = {};

        const serviceAddr: any = {
            address: serviceAddress.street,
            state: serviceAddress.state,
            city: serviceAddress.city,
            zipcode: serviceAddress.zipcode,
            county: serviceAddress.county || '',
            address2: serviceAddress.unitNo || ''
        }

        await openReplayTracker?.setUserID(inputValue?.email?.value);

        for (let i in inputValue) {
            if (i === 'email2') continue

            const item = inputValue[i]
            if (item.type === 'number') {
                saveFields[i] = item.value ? item.value.replace(/ /g, '') : ''
            } else if (item.type === 'date') {
                saveFields[i] = moment(item.value).format('MM/DD/YYYY')
            } else if (item.type === 'select') {
                saveFields[i] = item.value ? item.value.value : ''
            } else {
                saveFields[i] = item.value
                if(defaultEmail){
                    saveFields.email = "tgpwebcustomer@titangasandpower.com"
                    // saveFields.email2 = "tgpwebcustomer@titangasandpower.com"
                }
            }
        }

        saveFields.primary_phone_type = phoneType.value;

        for (const key in saveFields) {
            if(key === "first_name" || key === "last_name"){
                trueFields[key] = saveFields[key] ? saveFields[key]?.trim() : ""
            } else {
                trueFields[key] = saveFields[key]
            }
        }

        storePromoCode(inputValue.promo_code.value);

        let promises: Promise<any>[] = [];
        enrollmentTokens.forEach((item: any) => {
            const findPlan = selectedPlans.find((plan: any) => plan.plan_id === item.productId);
            if (findPlan) {
                const { enrollment_id, sign } = item;
                trueFields['product'] = findPlan.product;
                promises.push(saveData(trueFields, enrollment_id, sign));
            }
        })

        return Promise.all(promises);
    }

    const saveData = (requestData: any, enrollmentId: string, sign: string) => {
        return new Promise((resolve, rejects) => {
            saveEnrollment(enrollmentId, requestData, sign)
                .then(data => {
                    if (data && parseInt(data.status) === 1 && data.response) {
                        resolve(data.response);
                    }
                    else {
                        rejects(data.message);
                    }
                })
                .catch(error => rejects(error))
        })
    }

    const handleChangeInput = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValue)
        newInputValue[name as any].value = value
        setInputValue(newInputValue)
    }

    const getDynamicFields = (type: string) => {
        const group = dynamicFields[type]

        return (
            <>
                <EnrollmentFormRow>
                    {Object.keys(group).map((val, idx) => {
                        const item = group[val]
                        const { label, type, description } = item
                        if (type === 'checkbox') {
                            if (val === 'ext_paperless' && stateId !== 'NH') return null;
                            if (val === 'ext_sms_transactional_opt_in' && isAgent) return null;
                            return (
                                <EnrollmentFormCol full key={idx}>
                                    <CheckboxItem
                                        label={t(label)}
                                        value={`${inputValue[val] ? inputValue[val].value : ''}`}
                                        checked={inputValue[val] && inputValue[val].value}
                                        handleClick={() => {
                                            let newInputValue = Object.assign({}, inputValue)
                                            newInputValue[val].value = !newInputValue[val].value
                                            setInputValue(newInputValue)
                                        }}
                                        onlyread={false}
                                        disabled={loading}
                                        id={label}
                                    />
                                    {
                                        description &&
                                        <CheckboxDesc>{t(description)}</CheckboxDesc>
                                    }
                                </EnrollmentFormCol>
                            )
                        } 
                        else if (type === 'select') {
                            if (val === 'primary_phone_type') return null;
                            if (val === 'ext_delivery_method' && !isTx) return null;
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <Form.Group className={errorFields[val] ? 'error' : ''}>
                                        <Form.Label>{t(label)} *</Form.Label>
                                        <Dropdown className="plans-filter-dd">
                                            <Dropdown.Toggle
                                                disabled={(isAgent && val=="ext_delivery_method")?!agentBillDelivery:loading}
                                                variant="outline-secondary">
                                                {inputValue[val] && inputValue[val].value && !(val === 'ext_delivery_method' && isAgent && !inputValue[val].value)
                                                    ? t(inputValue[val].value.label)
                                                    : `${t('Please Select')}...`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {(item.value as Object[]).map(
                                                    (option: any, optionIdx) => {
                                                        return (
                                                            <Dropdown.Item
                                                                eventKey={option.label}
                                                                key={optionIdx}
                                                                onClick={() => {
                                                                    let newInputValue = Object.assign(
                                                                        {},
                                                                        inputValue
                                                                    )
                                                                    newInputValue[val].value = option
                                                                    setInputValue(newInputValue)
                                                                }}
                                                                active={
                                                                    inputValue[val] &&
                                                                    inputValue[val].value &&
                                                                    inputValue[val].value.value ===
                                                                        option.value
                                                                }>
                                                                {t(option.label)}
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        }

                        else if (type === 'date') {
                            // if (val === 'date_of_birth' && creditCheckType.value !== 'dob') return null;
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <Form.Group className={errorFields[val] ? 'error' : ''}>
                                        <Form.Label>{t(label)} *</Form.Label>
                                        <NumberFormat
                                            format="##/##/####"
                                            placeholder="MM/DD/YYYY"
                                            mask="_"
                                            name={val}
                                            value={inputValue[val].value}
                                            onChange={handleChangeInput}
                                            className="form-control"
                                            disabled={loading}
                                        />
                                    </Form.Group>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        } else {
                            if (val === 'ssn') {
                                // if (creditCheckType.value !== 'ssn') return null;
                                return (
                                    <EnrollmentFormCol key={idx}>
                                        <InputWithMask>
                                            <Form.Group className={errorFields[val] ? 'error' : ''}>
                                                <Form.Label>{t('SSN')} *</Form.Label>
                                                <NumberFormat
                                                    format="### ## ####"
                                                    mask="_"
                                                    name={val}
                                                    value={inputValue[val].value}
                                                    onChange={handleChangeInput}
                                                    className="form-control"
                                                    disabled={loading}
                                                />
                                                {
                                                    !showSSN &&
                                                    <NumberFormat
                                                        type="password"
                                                        maxLength={9}
                                                        name={val}
                                                        value={inputValue[val].value}
                                                        onChange={handleChangeInput}
                                                        className="form-control mask-input"
                                                        disabled={loading}
                                                    />
                                                }
                                            </Form.Group>
                                            {!isAgent && <InputMaskToggle type="button" active={showSSN} onClick={() => setShowSSN(!showSSN)} />}
                                        </InputWithMask>
                                        <FormError message={errorFields[val]} />
                                    </EnrollmentFormCol>
                                )
                            }
                            let labelText: any = `${t(label)}${Object.keys(requiredFields).indexOf(val) >= 0 ? ' *' : ''}`;
                            if (val === 'ext_raf_code') return null
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <Form.Group className={errorFields[val] ? 'error' : ''}>
                                        <Form.Label>{labelText}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={val}
                                            placeholder=""
                                            maxLength={50}
                                            value={inputValue[val] ? inputValue[val].value : ''}
                                            onChange={handleChangeInput}
                                            disabled={loading}
                                        />
                                    </Form.Group>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        }
                    })}
                </EnrollmentFormRow>
            </>
        )
    }

    if (!show) return null
    if (!dynamicFields) {
        return (
            <div className="d-flex justify-content-center pt-5 pb-5">
                <LoadingRow />
            </div>
        )
    }
    const handlePaste = (event:any) => {
        event.preventDefault(); // Prevent default paste behavior
      };

    return (
        <EnrollmentStepWrapper>
            <Form>
                <EnrollmentFormRow>
                    <EnrollmentFormCol>
                        <Form.Group className={errorFields['first_name'] ? 'error' : ''}>
                            <Form.Label>{t('First Name')} *</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                maxLength={50}
                                value={inputValue.first_name.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['first_name']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group className={errorFields['last_name'] ? 'error' : ''}>
                            <Form.Label>{t('Last Name')} *</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                maxLength={50}
                                value={inputValue.last_name.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['last_name']} />
                    </EnrollmentFormCol>
                    {(!isTx && isAgent)&&<EnrollmentFormCol full>
                        <div className="checkbox-row">
                            <CheckboxItem
                                label="Use default Email"
                                value=''
                                checked={defaultEmail}
                                handleClick={() => {
                                    setDefaultEmail(!defaultEmail);

                                }}
                                onlyread={false}
                                disabled={loading}
                                error={false}
                            />
                        </div>
                    </EnrollmentFormCol>}
                    <EnrollmentFormCol ref={emailInputRef}>
                        <Form.Group className={errorFields['email'] ? 'error' : ''}>
                            <Form.Label>{t('Email')} *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder=""
                                maxLength={150}
                                value={defaultEmail?"tgpwebcustomer@titangasandpower.com":inputValue.email.value}
                                onChange={(e)=>{handleChangeInput(e)
                                    setUserEmailForMagicLink(e.target.value)
                                    
                                }}
                                disabled={loading || defaultEmail}
                            />
                        </Form.Group>
                        <FormError message={errorFields['email']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group className={errorFields['email2'] ? 'error' : ''}>
                            <Form.Label>{t('Confirm Email')} *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email2"
                                placeholder=""
                                onPaste={(e:any)=>handlePaste(e)}
                                maxLength={150}
                                value={defaultEmail?"tgpwebcustomer@titangasandpower.com":inputValue.email2.value}
                                onChange={handleChangeInput}
                                disabled={loading || defaultEmail}
                            />
                        </Form.Group>
                        <FormError message={errorFields['email2']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group className={errorFields['phone'] ? 'error' : ''} style={{width:(!isTx && isAgent)?"max-content":"100%"}}>
                            <Form.Label>{t('Phone Number')} *</Form.Label>
                            <InputGroup >
                                <DropdownButton
                                    variant="outline-secondary"
                                    title={t(phoneType.label)}
                                    id="input-group-phone"
                                >
                                    {
                                        phoneTypeOptions.map((val, idx) => <Dropdown.Item key={idx} onClick={() => setPhoneType(val)}>{t(val.label)}</Dropdown.Item>)
                                    }
                                </DropdownButton>
                                <NumberFormat
                                    format="### ### ####"
                                    mask="_"
                                    name="phone"
                                    value={inputValue.phone.value}
                                    onChange={handleChangeInput}
                                    className="form-control"
                                    disabled={loading}
                                />
                            </InputGroup>
                        </Form.Group>
                        <FormError message={errorFields['phone']} />
                    </EnrollmentFormCol>
                    {(!isTx && isAgent)&&<EnrollmentFormCol>
                        <Form.Group className={errorFields['phone2'] ? 'error' : ''} style={{width:"max-content"}}>
                            <Form.Label>{t('Phone Number 2')} *</Form.Label>
                            <InputGroup >
                                <DropdownButton
                                    variant="outline-secondary"
                                    title={t(phoneType.label)}
                                    id="input-group-phone"
                                >
                                    {
                                        phoneTypeOptions.map((val, idx) => <Dropdown.Item key={idx} onClick={() => setPhoneType(val)}>{t(val.label)}</Dropdown.Item>)
                                    }
                                </DropdownButton>
                                <NumberFormat
                                    format="### ### ####"
                                    mask="_"
                                    name="phone2"
                                    value={inputValue.phone2.value}
                                    onChange={handleChangeInput}
                                    className="form-control"
                                    disabled={loading}
                                />
                            </InputGroup>
                        </Form.Group>
                        <FormError message={errorFields['phone2']} />
                    </EnrollmentFormCol>}
                    {
                        readOnlyPromoCode &&
                        <EnrollmentFormCol ref={promoCodeRef}>
                            <Form.Group className={errorFields['promoCode'] ? 'error' : ''}>
                                <Form.Label>{t('Promo Code')}<span style={{fontSize:14,marginLeft:4,opacity:.8}}>({t("Don't have one? That's ok!")})</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="promo_code"
                                    placeholder=""
                                    value={inputValue.promo_code.value}
                                    onChange={handleChangeInput}
                                    disabled={loading}
                                    readOnly={readOnlyPromoCode}
                                />
                            </Form.Group>
                            <FormError message={errorFields['promoCode']} />
                        </EnrollmentFormCol>
                    }
                    {
                        isTx &&
                        <EnrollmentFormCol>
                            <Form.Group>
                                <Form.Label>{t('Language')}</Form.Label>
                                <Dropdown className="plans-filter-dd">
                                    <Dropdown.Toggle disabled={loading} variant="outline-secondary">
                                        {inputValue.language.value ? t(inputValue.language.value.label) : ''}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {languageOptions.map((val, idx) => (
                                            <Dropdown.Item
                                                eventKey={val.label}
                                                key={idx}
                                                onClick={() => {
                                                    let newInputValue = Object.assign({}, inputValue);
                                                    newInputValue.language.value = val
                                                    setInputValue(newInputValue)
                                                }}
                                                active={
                                                    inputValue.language.value.value ===
                                                    val.value
                                                }>
                                                {t(val.label)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </EnrollmentFormCol>
                    }
                </EnrollmentFormRow>

                {isAgent && <ExistingCustomer
                    show={true}
                    enrTokens={enrollmentTokens}
                    inputValue={inputValue}
                    handleSavePre={handleSavePre}
                    checkedExistingCustomer={checkedExistingCustomer}
                    setCheckedExistingCustomer={setCheckedExistingCustomer}
                    setSamePremise = {setSamePremise}
                />}
                {(checkedExistingCustomer && samePremise && isAgent) &&
                    <>
                        <div>
                            <br/>
                            <div>You may not continue with this enrollment/switch via tha Agent Enrollment Portal.</div>
                            <div>Please direct the customer to use MyAccount</div>
                        </div>
                            <br/>
                        <div style={{display:"flex",justifyContent:"right"}}>
                        <Button
                            withArrow
                            type='button'
                            onClick={() => {
                                window.location.href = adminPortalAgent
                            }}>
                            {t('Back to Enrollment Portal')}
                        </Button>
                        </div>
                    </>
                }
                { checkedExistingCustomer && !samePremise &&
                    <>
                        <EnrollmentFormRow>
                            <EnrollmentFormCol full>
                                <CheckboxItem
                                    label={t('My billing address is the same as this service address')}
                                    value={`${isSameBillingAddress}`}
                                    checked={isSameBillingAddress}
                                    handleClick={() => {
                                        let newRequiredFields = Object.assign({}, requiredFields)
                                        if (isSameBillingAddress) {
                                            newRequiredFields['billing_address'] = { type: 'text' }
                                            newRequiredFields['billing_city'] = { type: 'text' }
                                            newRequiredFields['billing_state'] = { type: 'select' }
                                            newRequiredFields['billing_zipcode'] = { type: 'text' }
                                            newRequiredFields['billing_county'] = { type: 'text' }
                                        } else {
                                            if (newRequiredFields['billing_address'])
                                                delete newRequiredFields['billing_address']
                                            if (newRequiredFields['billing_city'])
                                                delete newRequiredFields['billing_city']
                                            if (newRequiredFields['billing_state'])
                                                delete newRequiredFields['billing_state']
                                            if (newRequiredFields['billing_zipcode'])
                                                delete newRequiredFields['billing_zipcode']
                                            if (newRequiredFields['billing_county'])
                                                delete newRequiredFields['billing_county']
                                        }
                                        setRequiredFields(newRequiredFields)
                                        setIsSameBillingAddress(!isSameBillingAddress)
                                    }}
                                    onlyread={false}
                                    disabled={loading}
                                    id='is-same-address'
                                />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        {!isSameBillingAddress && (
                            <div>
                                <EnrollmentFormRow>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billing_address'] ? 'error' : ''}>
                                            <Form.Label>{t('Billing Address Line 1')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="billing_address"
                                                placeholder=""
                                                maxLength={200}
                                                value={inputValue.billing_address.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['billing_address']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billing_address2'] ? 'error' : ''}>
                                            <Form.Label>{t('Unit/Suite/Apt Number')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="billing_address2"
                                                placeholder=""
                                                maxLength={100}
                                                value={inputValue.billing_address2.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['billing_address2']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billing_city'] ? 'error' : ''}>
                                            <Form.Label>{t('City')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="billing_city"
                                                placeholder=""
                                                maxLength={50}
                                                value={inputValue.billing_city.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['billing_city']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billing_county'] ? 'error' : ''}>
                                            <Form.Label>{t('County')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="billing_county"
                                                placeholder=""
                                                maxLength={100}
                                                value={inputValue.billing_county.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['billing_county']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billingState'] ? 'error' : ''}>
                                            <Form.Label>{t('State')} *</Form.Label>
                                            <Dropdown className="plans-filter-dd">
                                                <Dropdown.Toggle
                                                    disabled={loading}
                                                    variant="outline-secondary">
                                                    {billingState ? billingState?.name : 'State...'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {statesList.map((val, idx) => (
                                                        <Dropdown.Item
                                                            eventKey={val.abbr}
                                                            key={idx}
                                                            onClick={() => {
                                                                setBillingState(val)
                                                                let newInputValue = Object.assign(
                                                                    {},
                                                                    inputValue
                                                                )
                                                                newInputValue.billing_state.value = val.abbr
                                                                setInputValue(newInputValue)
                                                            }}
                                                            active={
                                                                inputValue.billing_state.value === val.abbr
                                                            }>
                                                            {val.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                        <FormError message={errorFields['billingState']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['billing_zipcode'] ? 'error' : ''}>
                                            <Form.Label>{t('Zip Code')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="billing_zipcode"
                                                placeholder=""
                                                maxLength={5}
                                                value={inputValue.billing_zipcode.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['billing_zipcode']} />
                                    </EnrollmentFormCol>
                                </EnrollmentFormRow>
                            </div>
                        )}
                        {
                            isAgent && isTx &&
                                <CheckboxItemNew
                                    label={'Agent must read the verbatim: How would you like to receive your monthly invoices, by Email (paperless) or by US Mail.'}
                                    value={String(agentBillDelivery)}
                                    checked={agentBillDelivery}
                                    handleClick={() => {
                                       setAgentBillDelivery(!agentBillDelivery)
                                    }}
                                    onlyread={false}
                                    disabled={loading}
                                    id={'Agent must read the verbatim: How would you like to receive your monthly invoices'}
                                />
                        }
                        {getDynamicFields('ext_input')}
                        {
                            isTx &&
                            <EnrollmentFormRow>
                                <EnrollmentFormCol full>
                                    <CheckboxItem
                                        label={t('I want to specify an authorized representative that is allowed to manage and change my account on my behalf')}
                                        value={`${enableAuthorizedRepresentative}`}
                                        checked={enableAuthorizedRepresentative}
                                        handleClick={() => {
                                            let newRequiredFields = Object.assign({}, requiredFields)
                                            if (!enableAuthorizedRepresentative) {
                                                newRequiredFields['authorized_first_name'] = { type: 'text' }
                                                newRequiredFields['authorized_last_name'] = { type: 'text' }
                                            } else {
                                                if (newRequiredFields['authorized_first_name'])
                                                    delete newRequiredFields['authorized_first_name']
                                                if (newRequiredFields['authorized_last_name'])
                                                    delete newRequiredFields['authorized_last_name']
                                            }
                                            setRequiredFields(newRequiredFields)
                                            setEnableAuthorizedRepresentative(!enableAuthorizedRepresentative)
                                        }}
                                        onlyread={false}
                                        disabled={loading}
                                        id='enable-authorized-representative'
                                    />
                                </EnrollmentFormCol>
                            </EnrollmentFormRow>
                        }
                        {
                            enableAuthorizedRepresentative &&
                            <div style={{marginTop:-24}}>
                                <EnrollmentFormRow>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['authorized_first_name'] ? 'error' : ''}>
                                            <Form.Label>{t('First Name')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="authorized_first_name"
                                                placeholder=""
                                                maxLength={200}
                                                value={inputValue.authorized_first_name.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['authorized_first_name']} />
                                    </EnrollmentFormCol>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['authorized_last_name'] ? 'error' : ''}>
                                            <Form.Label>{t('Last Name')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="authorized_last_name"
                                                placeholder=""
                                                maxLength={100}
                                                value={inputValue.authorized_last_name.value}
                                                onChange={handleChangeInput}
                                                disabled={loading}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['authorized_last_name']} />
                                    </EnrollmentFormCol>
                                </EnrollmentFormRow>
                            </div>
                        }
                          {
                            isAgent && SHOW_LINK_FOR_CREDIT_CHECK && isTx &&
                            <EnrollmentFormCol full style={{padding:"0px"}}>
                                    <CheckboxItem
                                        label={t('Manually enter SSN and DOB.')}
                                        value={String(ccConsent)}
                                        checked={ccConsent}
                                        handleClick={() => {
                                           setCcConsent(!ccConsent)
                                        }}
                                        disabled={false}
                                        id='enable-authorized-representative'
                                    />
                                    {
                                        <CheckboxDesc>{t('Please confirm consent from the customer has been obtained to enter the SSN and DOB')}</CheckboxDesc>
                                    }
                                </EnrollmentFormCol>
                        }
                        {
                            isTx &&
                            <CreditCheckSection>
                                <h5>{t('Utility Credit Check')}</h5>
                                <p dangerouslySetInnerHTML={{ __html: t('A utility soft credit check is necessary to assess whether a refundable deposit will be required. It does not affect your credit score. Your information is securely transmitted over an encrypted connection to CleanSky, a Texas Retail Energy Provider, which requires personal identification information to review and process your enrollment. CleanSky does not store, use, or sell any personal information collected.') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('For your security, we do not accept social security numbers over the phone. I will now send you a link to the email address you provided earlier, where you can enter your social security information. Please let me know when you are ready.')}} />
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="flex-fill">
                                        {(isAgent && SHOW_LINK_FOR_CREDIT_CHECK && !ccConsent) ? (
                                                <div>
                                                    <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Send Magic Link for SSN & DOB Fields:</div>
                                                    <div className='mt-2 d-flex'>
                                                            <input className='me-4' type='text' style={{borderRadius: '10px', border: '1px solid #CED4DA', width: '557px', height:'38px'}} placeholder={`enter email address`} value={userEmailForMagicLink} onChange={(e)=>setUserEmailForMagicLink(e.target.value)}/>
                                                            {magicLinkSentCreditCheck ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <a type='submit' onClick={()=>sendCreditCheckFieldsLink()}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                    <circle cx="16.5" cy="16.5" r="16.5" fill="#617692"/>
                                                                    <path d="M7.47839 16.571C6.81763 16.9176 6.84504 17.4253 7.53778 17.6995L9.59032 18.5116C10.2836 18.7868 11.3327 18.6366 11.9204 18.178L20.8336 11.1489C21.4195 10.6881 21.4823 10.756 20.9735 11.3008L13.9273 18.8428C13.4167 19.3859 13.5675 20.053 14.2631 20.3219L14.5035 20.4156C15.1991 20.6846 16.3327 21.1363 17.0238 21.4173L19.3002 22.3442C19.9918 22.6252 20.7308 22.294 20.9272 21.5744L24.3967 8.8217C24.5925 8.10212 24.2122 7.79658 23.5514 8.14267L7.47839 16.571Z" fill="white"/>
                                                                    <path d="M13.1111 24.9198C13.0705 25.0415 14.5148 22.8285 14.5148 22.8285C14.9232 22.2048 14.6913 21.4607 14.0014 21.1786L12.4263 20.5338C11.7364 20.2517 11.4058 20.5829 11.6913 21.2722C11.6913 21.2722 13.1528 24.7948 13.1111 24.9198Z" fill="white"/>
                                                                </svg>
                                                            </a>}
                                                    </div>
                                                    {linkSent && <div style={{marginTop: "10px", height: "fit-content", display:"flex",alignItems:"center"}}>
                                                        <img src="/images/checkFill.svg" height="24px" width="24px" /> &nbsp;
                                                        <div style={{color:"#254066",fontSize:"12px"}}>The Magic Link has been sent</div>
                                                    </div>}
                                                    <br/>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                                        <div>SSN: {formatSSN(creditCheckData.ssn) || "Not yet filled"} &nbsp;&nbsp; {!creditCheckData.ssn && <span style={{color: "red", fontSize: "15px"}}>Required</span>}</div>
                                                        <div>DOB: {creditCheckData.dob || "Not yet filled"} &nbsp;&nbsp; {!creditCheckData.dob && <span style={{color: "red", fontSize: "15px"}}>Required</span>}</div>
                                                    </div>
                                                    <br/>
                                                    {(!creditCheckData.dob || !creditCheckData.ssn) && <div className='col-md-12' style={{display: "flex", alignItems: "center"}}>
                                                        Refresh Status {fetchSsnDobLoader ? <CircularProgress size={"20px"} style={{marginLeft: "10px"}}/> : <img src="/images/refreshIcon.svg" height="24px" width="24px" style={{cursor: 'pointer', marginLeft: "10px"}} onClick={()=>fetchSsnDobData()}/>}
                                                    </div>}
                                                    <div></div>
                                                </div>
                                            )
                                            :getDynamicFields('credit_check')}
                                    </div>
                                </div>
                            </CreditCheckSection>
                        }
                        <EnrollmentFormRow style={{borderTop:'1px solid #eee',marginTop:20,paddingTop:20}}>
                            <EnrollmentFormCol full>
                                <CheckboxItem
                                    label={
                                        (!isTx || (creditCheckType && creditCheckType.value === 'none')) ? 
                                        t('I hereby authorize CleanSky Energy to perform all the necessary task to establish electricity service. I accept the terms and conditions of CleanSky Energy.') :
                                        t('I hereby authorize CleanSky Energy to run a credit check and perform all necessary tasks to establish electricity service. I accept terms and conditions of CleanSky Energy.')
                                    }
                                    value={`${isAgree}`}
                                    checked={isAgree}
                                    handleClick={() => {
                                        setIsAgree(!isAgree)
                                    }}
                                    onlyread={false}
                                    disabled={loading}
                                    id='is-agree-tos'
                                    error={errorFields['isAgree']}
                                />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        <EnrollmentStepFooter>
                            <Button type="button" secondary disabled={loading} onClick={() => handleBack()}>
                                {t('Back')}
                            </Button>
                            <Button type="button" onClick={() => handleSave()} data-test-id="cta">
                                <span>{t('Review')}</span>
                                {loading && (
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                        className="ms-2"
                                    />
                                )}
                            </Button>
                        </EnrollmentStepFooter>
                        {
                            Object.keys(errorFields).length > 0 &&
                            <div className="mt-4 text-end">
                                <FormError message={t(`Please correct error${Object.keys(errorFields).length > 1 ? 's' : ''} above`)} />
                            </div>
                        }
                    </>
                }

                
            </Form>
        </EnrollmentStepWrapper>
    )
}
