import { getEnrollmentRecord } from "../api/enrollment";

function parseStringToJson(inputString) {
    try {
      const parsedJson = JSON.parse(inputString);
      return parsedJson;
    } catch (error) {
      return null;
    }
}

  
export default async function selectedPlanMagicLink(idParam, signParam){
    let data = await getEnrollmentRecord(idParam, signParam)
    let resPlan = parseStringToJson(data?.response?.plan);
    if(resPlan){
        return [
            {
                "plan_type": resPlan?.id_plan_type,
                "plan_id": resPlan?.id_plan,
                "id_utility": resPlan?.id_utility,
                "plan_name": resPlan?.name,
                "plan_desc": resPlan?.description,
                "contract_term": resPlan?.term,
                "efl_url": resPlan?.plan_documents[0]?.url,
                "terms_url": resPlan?.market_documents[0]?.url,
                "yrac": resPlan?.plan_documents[1]?.url,
                "docs": [
                    ...resPlan?.market_documents,
                    ...resPlan?.plan_documents,
                ],
                "commodity_name": resPlan?.commodity_name,
                "commodity_clean_name": resPlan?.commodity_clean_name,
                "commodity_clean_per": resPlan?.commodity_clean_per,
                "rate": resPlan?.rate,
                "price500": resPlan?.rate500,
                "price1000": resPlan?.rate1000,
                "price2000": resPlan?.rate2000,
                "cancel_fee": resPlan?.cancellation_fee,
                "cancel_fee_type": "OneTime",
                "renewable": resPlan?.renewable_percent,
                "enroll_url": "",
                "base_fee1": 0,
                "energy_charge1": 0.08199999999999999,
                "agreed_acceptance_text": [
                    "I confirm my enrollment for Electricity supply service with CleanSky Energy and have read, understand, accept and agree to the Terms of Service, Electricity Facts Label and Your Rights as a Customer. I understand an electronic acceptance of the sales agreement is an agreement to initiate service and begin enrollment. I am 18 years of age or older, and legally authorized to change Electricity providers for the service address listed on this account and I authorize CleanSky Energy to perform all tasks necessary to complete the change in service I have requested."
                ],
                "product": resPlan?.id_plan,
                "utilityCode": ""
            }
        ]
    }
    return []
}