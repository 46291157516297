import { useEffect, useState } from "react";
import { Button } from '../../styles/Button.styled'
import { CheckboxDesc, CreditCheckSection, EnrollmentFormCol, EnrollmentFormRow, InputMaskToggle, InputWithMask } from "../../styles/Enrollment.styled";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { CheckboxItem } from "../../checkbox-item";
import { FormError } from "../FormError";
import { Form, Dropdown, Spinner, InputGroup, DropdownButton } from 'react-bootstrap'
import moment from "moment";
import { PlanSummary } from "../../PlanSummary";
import { getCreditCheckData, getPaymentURL, updateCreditCheckData } from "../../../api/api";
import { message } from "antd";




const CreditCheckScreen = () =>{
    const { t, i18n } = useTranslation('enrollment');
    const [errorFields, setErrorFields] = useState<any>({});
    const [ showSSN, setShowSSN ] = useState(false);
    const [requiredFields, setRequiredFields] = useState<any>({
        ssn: { type: 'text' },
        date_of_birth: { type: 'date' },
    })
    const [ formSubmitted , setFormSubmitted ] = useState(false)

    const [ inputValue , setInputValue ] = useState<any>({
            ssn: {
                type: "number",
                value: ""
            },
            date_of_birth: {
                type: "date",
                
            }
        }
    )

    useEffect(()=>{
        (async()=>{

            const fetchData = async () => {
                try {
                    const url = new URL(window.location.href);
                    const idParam = url.searchParams.get('id');
                    const signParam = url.searchParams.get('sign');
                    let res = await getPaymentURL(idParam, signParam);
                    if (res?.prospect_pay == true) {
                        window.location.href = res.url;
                        return true; 
                    } else {
                        return false; 
                    }
                } catch (error) {
                    return false; 
                }
                };
            await fetchData();
            await fetchSsnDobData();
        })()
    },[])

    // useEffect(() => {
    //     if(formSubmitted){
    //         const fetchData = async () => {
    //             try {
    //               console.log("i m called")
    //             } catch (error) {
    //               console.error('Error fetching data:', error);
    //             }
    //           };
          
    //           fetchData();
          
    //           const intervalId = setInterval(fetchData, 5000);
          
    //           return () => clearInterval(intervalId); 
    //     }   
    //   }, []);

    const loopRetrieveURL = async () => {
        const fetchData = async () => {
        try {
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');
            let res = await getPaymentURL(idParam, signParam);
            if (res?.prospect_pay == true) {
                window.location.href = res.url;
                return true; 
            } else {
                return false; 
            }
        } catch (error) {
            return false; 
        }
        };
    
        const continueLoop = async () => {
        let shouldContinue = await fetchData();
        if (!shouldContinue) {
            const intervalId = setInterval(async () => {
                shouldContinue = await fetchData();
                if(shouldContinue) {
                    clearInterval(intervalId);
                }
            }, 5000);
        }
        };
    
        await continueLoop();
    };

    const handleChangeInput = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValue)
        newInputValue[name as any].value = value
        setInputValue(newInputValue)
    }


    const getDynamicFields = (type: string) => {
        const dynamicFields:any = {
            "credit_check": {
                "ssn": {
                    "type": "text",
                    "label": "SSN"
                },
                "date_of_birth": {
                    "type": "date",
                    "label": "Date of Birth"
                }
            }
        }
        const group = dynamicFields[type]

        const validate = () => {
            let isValid = true
            let newErrorFields: any = {}
            
            for (let i in requiredFields) {
                const { type } = requiredFields[i]
                if (type === 'date') {
                    // if (
                        // (i === 'date_of_birth' && creditCheckType.value !== 'dob') ||
                        // isTx === false) continue;
                    if (
                        !inputValue[i]?.value ||
                        !(
                            moment(inputValue[i]?.value, 'MM/DD/YYYY', true).isValid()
                        )
                    ) {
                        newErrorFields[i] = t('Invalid Date')
                        isValid = false
                    }
                    if (i === 'date_of_birth') {
                        const age = inputValue[i]?.value ? moment().diff(moment(inputValue[i].value, 'MM/DD/YYYY'), 'years'):"";
                        
                        if (Number(age) < 18) {
                            newErrorFields[i] = t('Date of Birth is under 18 years')
                            isValid = false
                        }
                    }
                } 
                else {
                    if (!inputValue[i].value) {
                        // if (
                            // (i === 'ssn' && creditCheckType.value !== 'ssn') ||
                        // (i === 'ssn' && isTx === false)) continue;
                        newErrorFields[i] = t('This field is required')
                        isValid = false
                    }
                }
            }
    
            
            setErrorFields(newErrorFields)
            return isValid
        }
    
        const handleSave = async () => {
            if ( !validate()) return;
    
    
            let saveFields: any = {};
            let trueFields: any = {};
    
    
            for (let i in inputValue) {
                if (i === 'email2') continue
    
                const item = inputValue[i]
                    if (item?.type === 'number') {
                        saveFields[i] = item?.value ? item.value?.replace(/ /g, '') : ''
                    } else if (item?.type === 'date') {
                        saveFields[i] = moment(item.value)?.format('MM/DD/YYYY')
                    } else if (item?.type === 'select') {
                        saveFields[i] = item.value ? item.value?.value : ''
                    } else {
                        saveFields[i] = item?.value
                    }
            }
            try{
                const url = new URL(window.location.href);
                const idParam = url.searchParams.get('id');
                const signParam = url.searchParams.get('sign');

                let dobFormatted = moment(saveFields.date_of_birth).format("YYYY-MM-DD")
                await updateCreditCheckData(idParam, signParam, saveFields.ssn, dobFormatted);
                message.success({
                    content: 'SSN and DOB updated successfully',
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
                setFormSubmitted(true)
                loopRetrieveURL()
            }catch(err){
                message.error({
                    content: 'failed to update fields, please try again.',
                    style: {
                        position: 'absolute',
                        width: "100%",
                        top: "30px"
                    },
                });
            }
        }

        return (
            <EnrollmentFormRow>
                {Object.keys(group).map((val, idx) => {
                    const item = group[val]
                    const { label, type, description } = item
                    if (type === 'date') {
                        // if (val === 'date_of_birth' && creditCheckType.value !== 'dob') return null;
                        return (
                            <EnrollmentFormCol key={idx}>
                                <Form.Group className={errorFields[val] ? 'error' : ''}>
                                    <Form.Label>{t(label)} *</Form.Label>
                                    <NumberFormat
                                        format="##/##/####"
                                        placeholder="MM/DD/YYYY"
                                        mask="_"
                                        name={val}
                                        value={inputValue[val].value}
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        // disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields[val]} />
                            </EnrollmentFormCol>
                        )
                    } else {
                        if (val === 'ssn') {
                            // if (creditCheckType.value !== 'ssn') return null;
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <InputWithMask>
                                        <Form.Group className={errorFields[val] ? 'error' : ''}>
                                            <Form.Label>{t('SSN')} *</Form.Label>
                                            <NumberFormat
                                                format="### ## ####"
                                                mask="_"
                                                name={val}
                                                value={inputValue[val].value}
                                                onChange={handleChangeInput}
                                                className="form-control"
                                                // disabled={loading}
                                            />
                                            {
                                                !showSSN &&
                                                <NumberFormat
                                                    type="password"
                                                    maxLength={9}
                                                    name={val}
                                                    value={inputValue[val].value}
                                                    onChange={handleChangeInput}
                                                    className="form-control mask-input"
                                                    // disabled={loading}
                                                />
                                            }
                                        </Form.Group>
                                        <InputMaskToggle type="button" active={showSSN} onClick={() => setShowSSN(!showSSN)} />
                                    </InputWithMask>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        }
                    }
                })}
                <div style={{display:"flex",justifyContent:"flex-end",width:"100%",padding:"0px 25px"}}>
                    <Button type="button" onClick={()=>handleSave()} >
                            Continue
                    </Button>
                </div>
            </EnrollmentFormRow>
        )
    }

    const fetchSsnDobData = async() =>{
        try{
            // API call to fetch SSN DOB
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            const res = await getCreditCheckData(idParam, signParam)
            const data = res?.response;
            if(moment(data?.date_of_birth).isValid() && data?.ssn?.length === 9){
                setFormSubmitted(true)
                loopRetrieveURL()
            }
        }catch(err){
        }
    }

    return(
        <div style={{display:"flex",justifyContent:"center"}}>
            {formSubmitted ? <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <div style={{display:"flex",justifyContent:"center", fontSize:"24px",width:"70%",textAlign:"center"}}>Thank you for submitting your SSN and DOB.</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px",width:"70%",textAlign:"center"}}>Please let your CleanSky Energy CARE agent know that you have submitted your information, and a soft utility check will be performed to determine if a refundable deposit is required.</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px",fontWeight:"bold"}}>Do Not Close this Webpage</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px"}}>Please wait for instructions from your CARE agent</div>
                </div>:
             <div style={{margin:"32px 74px",maxWidth:"40%"}}>
             <CreditCheckSection>
                 <h5>{t('Utility Credit Check')}</h5>
                 <p>
                     {t('A soft credit check is required to proceed with your enrollment. This will not affect your credit score. A deposit may be required to proceed with enrollment. This deposit is entirely refundable upon the completion of your contract, provided you maintain timely bill payments throughout.  CleanSky does not store, use, or sell any personal information collected.')}
                 </p>
                 <div className="d-flex align-items-center justify-content-between">
                     <div className="flex-fill">
                         {getDynamicFields('credit_check')}
                     </div>
                 </div>
             </CreditCheckSection>
         </div>}
        </div>
    )
}

export default CreditCheckScreen;
