import { useContext, useEffect, useState } from 'react'
import { EnrollmentStepWrapper } from '../styles/Enrollment.styled'
import { EnrollmentContext } from '../../enrollment'
import { Successful } from './ConfirmationResults/Successful'
import { Rejected } from './ConfirmationResults/Rejected'
import { PendingDeposit } from './ConfirmationResults/PendingDeposit'
import { PendingDepositTx } from './ConfirmationResults/PendingDepositTx'
import { PendingVerification } from './ConfirmationResults/PendingVerification'
import { ContactLater } from './ConfirmationResults/ContactLater'
import { DualResult } from './ConfirmationResults/DualResult'
import { SuccessfulTx } from './ConfirmationResults/SuccessfulTx'
import isAdmin from '../../utils/isAdmin'
import AgentFinalScreen from './AgentWebPortal/AgentFinalScreen'
import { fetchIsWhitelisted, getAgentScript, updateStatus } from '../../api/api'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import isMagicLink from '../../utils/isMagiclink'

interface Props {
    show: boolean
    result: any
    isTx: boolean
    handleReset: Function;
    customerName: string;
    showFinalPage?: any;
    setStep: Function;
    setScriptData?: any;
}

export const EnrollmentStepConfirmation: React.FC<Props> = ({
    result, show, isTx, handleReset, customerName, showFinalPage,setScriptData, setStep
}) => {
    const { t,i18n} = useTranslation('enrollment')
    const { selectedPlans, enrollmentTokens } = useContext<any>(EnrollmentContext);
    const [isAgent,setAgent] = useState(false)
    const [whitelisted,setWhitelisted] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const screenIds = {
        deposit: "4",
        autopay: "5",
        fraud: "6"
    }

    const getScriptForAgent = async(script_id: string)=>{
        try{
            let body= {};
                if(enrollmentTokens[0]?.enrollment_id){
                    body = {
                        script_id: script_id,
                        language:i18n.language === 'es' ? 'spanish' : 'english',
                        state: isTx ? "TX" : "OH",
                        enrollment_id: enrollmentTokens[0]?.enrollment_id,
                        commodity: (selectedPlans.length > 1) ? "gas" : selectedPlans[0]?.commodity_name,
                        utility_code: selectedPlans[0]?.id_utility,
                    }
                }
                else{
                    body = {
                        script_id: script_id,
                        language:i18n.language === 'es' ? 'spanish' : 'english',
                        state: isTx ? "TX" : "OH",
                        commodity: (selectedPlans.length > 1) ? "gas" : selectedPlans[0]?.commodity_name,
                        utility_code: selectedPlans[0]?.id_utility,
                    }
                }
            const res = await getAgentScript(body)
            setScriptData({
                //@ts-ignore
                script: res.length>0 ? res[0]?.script_content:"",
                scriptHead: res.length>0 ? res[0]?.screen_name:"",
                dynamicData:{}
            })
        }catch(e){
            console.log(e)
        }
    }
    
    useEffect(() => {
        if (show) {
            window.scrollTo(0, 0);
        }
    }, [show])


    useEffect(() => {
        if (show && !result) {
            handleReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, result])

    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setAgent(_isAdmin)

            const url = new URL(window.location.href);
            const enrollmentIdParam = url.searchParams.get('id');
            const resp = await fetchIsWhitelisted(enrollmentIdParam);
            setWhitelisted(Boolean(resp?.whitelist_flag))
        })();
    },[])



    const showResult = () => {
        if (result) {
            const isSame = result.length === 1 || result[0].status === result[1].status;
            const isAutopayOverride = Boolean(result[0].autopay_override);
            const autopayRequired = result[0].autopay_required =="true"?true:false;

            const MLAutopayOverride = Boolean(result[0]?.additional[0]?.autopay_override);
            const MLAutopayCompleted = Boolean(result[0]?.additional[0]?.autopay_completed);

            if (isSame) {
                const { additional, message, status } = result[0]
                const errorCode = status?.split('.')[0]
                const next_step = result[0]?.next_step
                if(isAgent){
                    if(isAutopayOverride){
                        // setStep(100);
                        // navigate(`/autopaysuccess${window.location.search}`, {state: {
                        //     pagename: "AutopaySuccess",
                        //     selectedPlans: selectedPlans,
                        //     selectedPlansML: null,
                        //     enrollmentTokens: enrollmentTokens[0],
                        //     autopayOverride: true,
                        //     customerName:customerName
                        // }})
                        setStep(3)
                        window.scrollTo(0, 0);
                        navigate(`/review${location.search}&overrideCustomer=true`);
                        return;
                    }
                    switch (errorCode) {
                        case '10':
                            //status No-Fraud-Detected Autopay Autopay-Pending
                            // updateStatus(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, "10.E02", "Autopay-Pending", "No-Fraud-Detected")
                            return isTx ? (                                
                                //SuccessfulTx
                                    getScriptForAgent(screenIds.autopay),
                                    setStep(100),
                                    navigate(`/autopay${window.location.search}`, {state: {
                                        pagename: "AgentSuccessfulTx",
                                        enrollmentTokens:enrollmentTokens[0]
                                    }})
                                ) : (
                                    <Successful 
                                        status={status}
                                        selectedPlans={selectedPlans}
                                        enrollmentTokens={enrollmentTokens[0]}
                                        getScriptForAgent={getScriptForAgent}
                                    />
                                )
                        case '20':
                            //status No-Fraud-Detected Deposit Deposit-Pending
                            // updateStatus(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, "20.E01", "Deposit-Pending", "No-Fraud-Detected")
                            getScriptForAgent(screenIds.deposit)
                            return isTx ? (
                                    //PendingDepositTX
                                    setStep(100),
                                    navigate(`/deposit${window.location.search}`, {state: {
                                        pagename: "AgentPendingDepositTx",
                                        additional:additional,
                                        name:customerName,
                                        enrollmentTokens:enrollmentTokens[0],
                                        selectedPlans:selectedPlans,
                                        showFinalPage:showFinalPage
                                    }}) 
                                ) :
                                <PendingDeposit additional={additional} getScriptForAgent={getScriptForAgent}/>
                        case '30':
                            //status Fraud-Detected Fraud-Warning Fraud-Blocked
                            // updateStatus(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, "30.E09", "Fraud-Blocked", "Fraud-Detected")
                            getScriptForAgent(screenIds.fraud)
                            return (
                                //PendingVerification
                                setStep(100),
                                navigate(`/verify${window.location.search}`, {state: {
                                    pagename: "AgentPendingVerification",
                                    status: status,
                                    next_step: next_step
                                }}) 
                            )
                        case '40':
                            return  <Successful 
                            status={status}
                            selectedPlans={selectedPlans}
                            enrollmentTokens={enrollmentTokens[0]}
                            getScriptForAgent={getScriptForAgent}
                        />
                        case '50':
                            return <Rejected message={message} />
                        default:
                            return null
                    }

                }else{
                    const url = new URL(window.location.href);
                    const enrollmentIdParam = url.searchParams.get('id');
                    const signParam = url.searchParams.get('sign');
                    const agentParam = url.searchParams.get('isAgent');

                    if(isAutopayOverride || (MLAutopayOverride && !MLAutopayCompleted)){
                        setStep(100);
                        navigate(`/txconfirmation${window.location.search}`, {state: {
                            pagename: "AutopaySuccess",
                            selectedPlans: selectedPlans,
                            selectedPlansML: null,
                            enrollmentTokens: enrollmentTokens[0],
                            autopayOverride: true
                        }})
                        return;
                    }else if(isAutopayOverride || (MLAutopayOverride && MLAutopayCompleted)){
                        setStep(100);
                        navigate(`/autopaysuccess${window.location.search}`, {state: {
                            pagename: "AutopaySuccess",
                            selectedPlans: selectedPlans,
                            selectedPlansML: null,
                            enrollmentTokens: enrollmentTokens[0],
                            autopayOverride: false
                        }})
                        return;
                    }

                    switch (errorCode) {
                        case '10':
                            if(isTx){
                                if(isMagicLink() && agentParam !== "true" && whitelisted){
                                    //status Fraud-Whitelisted Autopay Autopay-Pending-Whitelisted
                                    // updateStatus(enrollmentIdParam, signParam, "10.E02", "Autopay-Pending-Whitelisted", "Fraud-Whitelisted")
                                }else{
                                    //status No-Fraud-Detected Autopay Autopay-Pending
                                    // updateStatus(enrollmentTokens[0]?.enrollment_id || enrollmentIdParam, enrollmentTokens[0]?.sign || signParam, "10.E02", "Autopay-Pending", "No-Fraud-Detected")
                                }
                            }else{
                                // updateStatus(enrollmentTokens[0]?.enrollment_id || enrollmentIdParam, enrollmentTokens[0]?.sign || signParam, "40.E04", "Completed", null)
                            }
                            return isTx ?
                                (
                                    //SuccessfulTx
                                    setStep(100),
                                    navigate(`/autopay${window.location.search}`, {state: {
                                        pagename: "SuccessfulTx",
                                        status:status,
                                        name:customerName,
                                        enrollmentTokens:enrollmentTokens[0],
                                        selectedPlans:selectedPlans,
                                        showFinalPage:showFinalPage
                                    }})
                                ) :
                                <Successful 
                                    status={status}
                                    selectedPlans={selectedPlans}
                                    enrollmentTokens={enrollmentTokens[0]}
                                />
                        case '20':
                            if(isMagicLink() && agentParam !== "true" && whitelisted){
                                //status Fraud-Whitelisted Deposit Deposit-Pending-Whitelisted
                                // updateStatus(enrollmentIdParam, signParam, "20.E01", "Deposit-Pending-Whitelisted", "Fraud-Whitelisted")
                            }else{
                                //status No-Fraud-Detected Deposit Deposit-Pending
                                // updateStatus(enrollmentTokens[0]?.enrollment_id || enrollmentIdParam, enrollmentTokens[0]?.sign || signParam, "20.E01", "Deposit-Pending", "No-Fraud-Detected")
                            }
                            return isTx ?
                                (
                                    //PendingDepositTX
                                    setStep(100),
                                    navigate(`/depositoptions${window.location.search}`, {state: {
                                        pagename: "DepositOptions",
                                        additional:additional,
                                        name:customerName,
                                        enrollmentTokens:enrollmentTokens[0],
                                        selectedPlans:selectedPlans,
                                        showFinalPage:showFinalPage,
                                        autopayRequired:autopayRequired
                                    }}) 
                                ) :
                                <PendingDeposit
                                    additional={additional}
                                />
                        case '30':
                            //status Fraud-Detected Fraud-Warning Fraud-Blocked
                            // updateStatus(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, "30.E09", "Fraud-Blocked", "Fraud-Detected")
                            return (
                                //PendingVerification
                                setStep(100),
                                navigate(`/verify${window.location.search}`, {state: {
                                    pagename: "PendingVerification",
                                    status: status,
                                    next_step: next_step
                                }}) 
                            )
                        case '40':
                            return <Successful 
                            status={status}
                            selectedPlans={selectedPlans}
                            enrollmentTokens={enrollmentTokens[0]}
                        />
                        case '50':
                            return <Rejected
                                        message={message}
                                    />
                        default:
                            return null
                    }
                }
            }
            else {
                let statusArr: any = [];
                result.forEach((res: any) => {
                    const findPlan = selectedPlans.find((plan: any) => res.productId === plan.plan_id);
                    if (findPlan) {
                        statusArr.push({
                            commodity: findPlan.commodity_name,
                            status: res.status
                        })
                    }
                })
                return <DualResult statusArr={statusArr} />
            }
        }
    }
    
    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            <div id="enrollment-confirmation-page" className="p-4">{showResult()}</div>
        </EnrollmentStepWrapper>
    )
}
