import styled from 'styled-components';

export const StyledHeader = styled.header`
    background-color: #eff7fa;
`

export const Logo = styled.a`
    text-decoration: none;
    color: #666;
    font-size: 12px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    
    img {
        height: 32px;
    }

    @media (max-width: 991px) {
        padding: 10px 0;

        img {
            height: 41px;
        }
    }
`

export const StyledHeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    button {
        box-shadow: none;
        margin-right: 1rem;

        :last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 991px) {
        width: 90%;
    }
`

export const HeaderMenu = styled.div`
    display: flex;
    align-items: center;

    a.btn {
        margin-left: 12px;
    }

    a.orangeButton {
        zoom: 1.1;
    }

    @media (max-width: 991px) {
        display: ${({ active }: { active: string }) => (active && active === 'true') ? 'flex' : 'none'};
        position: fixed;
        top: 61px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.375rem 0px 0.625rem 3.5rem;
        
        button {
            margin-bottom: 1.375rem;
            font-size: 1.25rem;
            padding: 0.75rem 2.125rem;
        }

        a.btn {
            margin-left: 0;
            margin-top: 16px;
        }
    }
`

export const HeaderMenuItem = styled.a`
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    font-size: 15px;
    color: #33302C;
    padding: 10px 12px;
    transition: all 0.25s ease 0s;
    margin-right: 12px;
    text-transform: uppercase;

    :hover {
        color: #f15536;
    }

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 991px) {
        &:last-of-type {
            margin-bottom: 40px;
        }
    }
`

export const MenuToggle = styled.button`
    cursor: pointer;
    border: 0 none;
    mask-image: ${({ active }: { active: string }) => (active && active === 'true') ? 'url("/images/X.svg")' : 'url("/images/Menu.svg")'};
    mask-position: 0 0;
    mask-repeat: no-repeat;
    background-color: #888;
    width: 24px;
    height: 24px;
    mask-size: cover;
    display: none;
    margin-right: 0 !important;

    @media (max-width: 991px) {
        display: inline-block;
        padding: 0;
    }
`

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const PageContainer = styled.div`
    flex: 1;
`