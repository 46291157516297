// in the config
import React from 'react';

import {
  createChatBotMessage,
  createCustomMessage,
} from 'react-chatbot-kit';
import  {CustomMessage, CustomHeader } from './CustomMessage';

const botName = 'Agent Script';

const customHeader = (
  <div>
    <img src='/images/logo.svg' alt="Your Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
    <span>Your Chatbot Title</span>
  </div>
);

const config = (data) => {
    return{
      botName: botName,
      lang: 'no',
      customStyles: {
        botMessageBox: {
          color: '#284168',
          backgroundColor: '#EFF7FA',
        },
        chatButton: {
            backgroundColor: '#5ccc9d',
        },
      },
      initialMessages: [
        // createChatBotMessage(
        //   `Hi I'm ${botName}. Bhaiyya Jo ye message dikh rha vo config.js me milega, wahan se change kr sakte ho aap`
        // ),
        // createChatBotMessage(
        //   "Aur ye niche dog ka pic hai vo CustomMessage naam ke ek component se aa rha hai, baaki iske documentation me aur bhi cutomization diya hai, but jyada nii hai",
        //   {
        //     withAvatar: false,
        //     delay: 100,
        //   }
        // ),
        createCustomMessage('Test', 'custom'),
        // createChatBotMessage(
        //     msg,
        //     {
        //         withAvatar: false,
        //         delay: 10,
        //     }
        // )
      ],
      state: {
        gist: '',
        infoBox: '',
      },
      customComponents: {},
      customMessages: {
        custom: (props) => <CustomMessage {...props} data={data} />,
      },
      customHeader:  {
        custom: (props) => <CustomHeader {...props} />,
      },
      widgets: [],
    };
}

export default config;