import React from 'react';
import { Spinner } from 'react-bootstrap';
import type { LoadingRowProps } from '../types';

export const LoadingRow: React.FC<LoadingRowProps> = ({ size }) => {
    if (size === 'sm') {
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
        )
    }
    return (
        <div style={{display:'flex',justifyContent:'center'}}>
            <Spinner animation="border" variant="secondary" />
        </div>
    )
}