import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const Footer = ({ isTx }: {
    isTx: boolean
}) => {
    const { t, i18n } = useTranslation();
    const [ selectedLicenseState, setSelectedLicenseState ] = useState('DC');

    const licenseContent: any = {
        'DC': t('CleanSky Energy is a licensed supplier of electricity and natural gas supply in the District Of Columbia. D.C. Lic#- Elec EA-2017-15, Gas GA-2017-09'),
        'IL': t('CleanSky Energy is certified to provide electricity in the state of Illinois. ICC ARES Certificate: 18-1266'),
        'MA': t('CleanSky Energy is a licensed competitive supplier in the state of Massachusetts. Mass DPU License: CS-177'),
        'MD': t('CleanSky Energy is a licensed supplier in the state of Maryland. Electric License: IR-4126, Gas License: IR-4127'),
        'NJ': t('CleanSky Energy is a licensed Third-Party Supplier (“TPS”) for electricity and natural gas in the State of New Jersey. NJ License Number - ESL-0255 (Power) / GSL-0223 (Gas).'),
        'OH': t('CleanSky Energy is certified to provide electricity and natural gas supply in the State of Ohio. PUCO Certificates: Electric: 13-700E, Natural Gas: Cert # 15-485G'),
        'PA': t('CleanSky Energy operates as an Electric Generation Supplier (“EGS”) and Natural Gas Supplier (“NGS”) in the state of Pennsylvania. Electric License: A-2016-2530633, Gas License: A-2008-2054272.'),
        'TX': t('CleanSky Energy is a certified retail energy provider in the state of Texas. PUCT# 10268'),
        'DE': t('CleanSky Energy is a licensed retail energy provider in the state of Delaware. Order #10080'),
        'NH': t('')
    }
    
    const isEs = i18n.language === 'es';
    
    return (
        <>
            <div className="bottom-curve">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1922.3 211.4" >
                    <path className="st0" d="M0,209V0c35.6,7.2,109.8,21.8,207.8,39.3C324.5,60.1,441.1,79,554.2,95.4c147.7,21.4,288,38.3,417.1,50.2
                    c74.7,6.9,146.6,12.2,213.8,15.9c35.8,1.9,70.9,3.4,104.5,4.4c34.9,1,69,1.5,101.5,1.5h0.1c28.9,0,57.2-0.4,84.2-1.2
                    c27.8-0.8,54.8-2.1,80.4-3.8c26.4-1.8,52-4,76.1-6.6c24.8-2.7,48.7-6,71-9.7c22.9-3.8,44.9-8.1,65.3-12.9
                    c20.9-4.9,40.7-10.5,58.9-16.4c17.6-5.8,34.9-12.5,51.8-20.2c15.1-7,29.8-15.1,43.9-24.2V209H0z"></path>
                </svg>
            </div>
            <footer className="footer lightGrayBack">
                <div className="upper-footer">
                    <div className="footer-logo">
                        <a href="https://cleanskyenergy.com">
                            <img alt=" " src="https://cleanskyenergy.com/wp-content/uploads/2023/03/Logo-Vertical_Footer.svg" />
                        </a>
                    </div>
                    <div className="footer-menu-container">
                        <ul className="footer-menu">
                            <li className="footer-main-li">
                                <a className="footer-main lnk" href="https://cleanskyenergy.com/natural-gas-electricity-plans/">{t('Plans')}</a>
                                <ul className="footer-sub-menu">
                                    <li>
							            <a className="footer-sub lnk" href={isEs ? "https://cleanskyenergy.com/es/electricidad-residencial/" : "https://cleanskyenergy.com/residential-electricity/"}>{t('Electricity for Home')}</a>
						            </li>
									<li>
                                        <a className="footer-sub lnk" href={isEs ? "https://cleanskyenergy.com/es/negocio-electricidad/" : "https://cleanskyenergy.com/business-electricity/"}>{t('Electricity for Business')}</a>
                                    </li>
                                    {
                                        (!isEs && !isTx) &&
                                        <>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/residential-natural-gas/">{t('Gas for Home')}</a>
                                            </li>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/commercial-natural-gas/">{t('Gas for Business')}</a>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </li>
                            <li className="footer-main-li">
                                <a className="footer-main lnk" href="https://cleanskyenergy.com/our-story/">{t('About Us')}</a>
                                <ul className="footer-sub-menu">
                                    <li>
                                        <a className="footer-sub lnk" href={isEs ? "https://cleanskyenergy.com/es/preguntas-frecuentes/" : "https://cleanskyenergy.com/faq/"}>{t('FAQs')}</a>
                                    </li>
                                    {
                                        !isEs &&
                                        <>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/careers/">{t('Careers')}</a>
                                            </li>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/reviews/">{t('Reviews')}</a>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </li>
                            <li className="footer-main-li">
                                <a className="footer-main lnk">{t('Resources')}</a>
                                <ul className="footer-sub-menu">
                                    {
                                        !isEs &&
                                        <>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/blog/">{t('Blog')}</a>
                                            </li>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/outage-phone-numbers/">{t('Outage Numbers')}</a>
                                            </li>
                                        </>
                                    }
                                    <li>
                                        <a className="footer-sub lnk" href={isEs ? "https://cleanskyenergy.com/es/electricidad-texas/" : "https://cleanskyenergy.com/texas-electricity/"}>{t('Texas Electricity')}</a>
                                    </li>
                                    {
                                        !isEs &&
                                        <>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/variable-rate-history/">{t('Variable Rate History')}</a>
                                            </li>
                                            <li>
                                                <a className="footer-sub lnk" href="https://cleanskyenergy.com/sitemap/">{t('Sitemap')}</a>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </li>
                            <li className="footer-main-li contact-column">
                                <a className="lnk footer-main" href="https://cleanskyenergy.com/contact-us/">{t('Contact Us')}</a>
                                <p>{t('Customer Service Hours: M-F, 8am-7pm and Sat 9am–1pm CST')}</p>
                                <span className="state_name">{t('For Texas Customers')}</span>
                                <a className="lnk footer-phone" href="tel:1-888-733-5557">1-888-733-5557</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lower-footer">
                    <div className="legal"><p>
                        {
                            isEs ? <>Consulte nuestra Política de privacidad si tiene preguntas legales sobre nuestros servicios. © 2022 CleanSky Energy. Reservados todos los derechos.</> : <>Please see our <a href="https://cleanskyenergy.com/terms-of-use/">Privacy Policy</a> for legal questions regarding our services. © 2023 CleanSky Energy. All rights reserved.</>
                        }
                        {/* <Trans i18nKey="footerPrivacyPolicy">
                            Please see our <a href="https://cleanskyenergy.com/terms-of-use/">Privacy Policy</a> for legal questions regarding our services. © 2023 CleanSky Energy. All rights reserved.
                        </Trans> */}
                        </p></div>
                    <div className="legal container">
                        <p className="license-text me-1">{t('Licensed in')}</p>
                        <ul className="tab_navigation">
                            {
                                Object.keys(licenseContent).map((state: string, idx: number) => {
                                    if (isEs) {
                                        if (state === 'DE' || state === 'NH') return null;
                                    }
                                    return (
                                        <li key={idx}>
                                            <a onClick={() => setSelectedLicenseState(state)}>{state}</a><i>,</i>
                                        </li>
                                    )}
                                )
                            }
                        </ul>
                        <div className="tab_container_area">
                            <div className="tab_container" style={{display:'block'}}>
                                <p>{licenseContent[selectedLicenseState]}</p>
                            </div>      
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer