import Avatar from '@mui/material/Avatar';

const getInitialsFromAgentId = () => {
    try{
        const params = new URLSearchParams(window.location.search);
        const agentId = params.get('agent_id');
      
        if (agentId) {
            const initials = `${agentId[0]}${agentId[1]}`
            return initials;
        }
      
        return null;
    }catch(err){
        return "CA"
    }
};

const AvatarRenderer = () => {
    const initials = getInitialsFromAgentId();
    console.log('Data in initials ==>', initials);
    return (
        <div>
            <Avatar>
                {initials}
            </Avatar>
        </div>
    );
};

export default AvatarRenderer;
