import React, { useEffect, useState } from 'react';
import { Button } from './styles/Button.styled';
import { getConfigById } from '../api/api';
import moment from 'moment';
import isAdmin from '../utils/isAdmin';

function getCookie(cookieName: string) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
  
    for(let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
}

function setCookie(cookieName: string, cookieValue: string, expirationDays: number) {
    const date = new Date();
    date.setTime(date.getTime() + (Number(expirationDays) * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

const CookiePopup: React.FC<{setCookiesAccepted: Function}> = ({setCookiesAccepted}) => {
  const [showPopup, setShowPopup] = useState(false);


  useEffect(()=>{
    (async()=>{
      const url = new URL(window.location.href);
      const planPageDecision = url.searchParams.get('cookiesAccepted');
      if(planPageDecision != null){
        if(planPageDecision == "true"){
          handleAccept()
        }else{
          handleReject()
        }
      }else{
        const res = await getConfigById("COOKIE_MANAGEMENT")
        const admin = await isAdmin();
        if(!admin){
            if(res?.config_details?.ConfigData == "1"){
              let res = getCookie('cookiesAccepted');
              if(res){
                  setShowPopup(false)
              }else{
                  setShowPopup(true)
              }
            }else{
              setCookie("cookiesAccepted", "true", 1000000)
            }
        }
      }
    })()
   
  },[])

  const handleAccept = () => {
    // Set a cookie to indicate that the user has accepted the cookies
    // const oneYearFromNow = new Date();
    // oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie("cookiesAccepted", "true", 1000000);
    setCookie("cookiesAcceptedDate", moment().format('YYYY-MM-DD'), 1000000);
    setShowPopup(false);
    document.body.style.overflow = 'auto';
    setCookiesAccepted(true)
  };

  const handleReject = () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie("cookiesAccepted", "false", 365);
    setCookie("cookiesAcceptedDate", moment().format('YYYY-MM-DD'), 365);
    setShowPopup(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      {showPopup && (
        <>
          <div className="cookie-overlay"></div>
          <div className="cookie-popup">
            <div style={{fontSize:"28px",fontWeight:600}}>How we use Cookies</div>
            <p style={{margin:"10px 0px 24px 0px"}}>We use cookies to give you the best possible experience with CleanSkyEnergy.com. Some are essential for this site to function; others help us understand how you use the site, so we can improve it. We may also use cookies for targeting purposes. Click “Accept all cookies” to proceed as specified, or click “Reject all cookies".</p>
            <div className="cookie-buttons">
              <Button secondary onClick={handleAccept}>Accept all cookies</Button>
              <Button onClick={handleReject} style={{ marginLeft: '10px' }}>Reject all cookies</Button>
            </div>
          </div>
        </>
      )}
      <style>{`
        .cookie-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
          z-index: 999; /* Ensure it's on top of other content */
        }

        .cookie-popup {
          position: fixed;
          bottom: 36px;
          left: 5%;
          width: 90%;
          background-color: #eff7fa;
          padding: 20px 28px;
          border-radius: 8px;
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
          z-index: 1000; /* Ensure it's on top of the overlay */
        }

        .cookie-buttons {
          display: flex;
          justify-content: flex-start;
        }
      `}</style>

    </>
  );
};

export default CookiePopup;
