import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { Button } from "./components/styles/Button.styled"
import { ResultPage } from "./components/styles/Enrollment.styled"

const Success = () => {
    const location = useLocation();
    const { enrollmentId } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        })

    return (
        <ResultPage>
            <div className="text-center">
                <h1 className="mb-3">Welcome to CleanSky Energy</h1>
                {
                    enrollmentId &&
                    <p><span>Confirmation ID #{enrollmentId}</span></p>
                }
                <p>Your enrolment has been received. Please wait for an email confirmation from CleanSky including the plan details. Once approved by your local utility company, you'll receive a second email confirming your official start date.</p>
                <p>Thank you for choosing CleanSky Energy!</p>
                <div className="mt-5">
                    <Button
                        withArrow
                        onClick={() => {
                            window.location.href = `https://cleanskyenergy.com/`
                        }}>
                        Back to Homepage
                    </Button>
                </div>
            </div>
        </ResultPage>
    )
}

export default Success